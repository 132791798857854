.transaction_tab .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--light-second-gray);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.transaction_tab .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: none;
  padding: 9px 3px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  letter-spacing: 0.7px;
}

.transaction_tab .nav-link:hover {
  color: var(--primary-second) !important;
}

.transaction_tab .nav-tabs .nav-link.active {
  color: var(--primary-second) !important;
  /* font-weight: bold; */
  border-color: transparent;
  border-bottom: 3px solid var(--primary-second);
  background-color: transparent;
}

/* TransactionTable.css */
.table-container {
  border-radius: 8px;
}

.table_transaction {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.transaction_row {
  background-color: #ffff;
}
.table_transaction td {
  padding: 12px 10px;
  text-align: left;
}

.table_transaction th {
  padding: 12px 10px;
  text-align: left;
  padding-bottom: 0px;
  color: #555555;
}

.table_transaction tr td,
.table_transaction th {
  font-size: 16px;
  line-height: 19.2px;
  font-family: "Lato";
  color: var(--dark-gray);
  font-weight: 400;
  text-wrap: nowrap;
}

.table_transaction tbody tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table_transaction tbody tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.table_transaction td:first-child,
th:first-child {
  padding-left: 25px;
}
.table_transaction td:last-child,
th:last-child {
  padding-right: 25px;
}

.table_transaction .transection_image {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: 1px solid #afb8c3;
  border-radius: 5px;
}

.table_transaction .transaction_row td span.success,
td span.rejected,
td span.pending {
  border-radius: 20px;
  padding: 5px 13px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  letter-spacing: 0px;
}
.table_transaction .transaction_row .rejected {
  background-color: #ffe4e4;
  color: #ec0404;
}
.table_transaction .transaction_row .success {
  background-color: #aaffc1;
  color: #019668;
}
.table_transaction .transaction_row .pending {
  background-color: #fffab9;
  color: #e0b130;
}
.filter-container {
  position: relative;
  display: inline-block;
}

.dropdown1 {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  border-radius: 10%;
  padding: 10px;
  display: none;
  max-height: none;
}

.filter-container .dropdown1 {
  display: block;
}

.dropdown1 div {
  padding: 8px;
  cursor: pointer;
}

.dropdown1 div:hover {
  background-color: #f0f0f0;
}

/* Table Filter CSS */

.search-filter-box {
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 8px;
}

.search-input {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  width: max-content;
}

.filter-option {
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
}

.filter-option:hover {
  background-color: #f0f0f0;
}

@media (min-width: 992px) {
  .table_transaction {
    border-spacing: 0px 20px;
  }

  .table_transaction td {
    padding: 20px 15px;
  }
  .table_transaction th {
    padding: 20px 15px;
    padding-bottom: 0px;
  }
  .table_transaction td:first-child,
  th:first-child {
    padding-left: 29px;
  }
  .table_transaction td:last-child,
  th:last-child {
    padding-right: 29px;
  }
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Optional background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above all other content */
}

/* Invester css */

.investor-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
}

.investor-status-active {
  background-color: #aaffc1;
  color: #019668;
  padding: 5px 16px;
  width: 66px;
  border-radius: 14px;
}

.investor-status-inactive {
  background-color: #ffdfbf;
  color: #f5820a;
  padding: 5px 12px;
  width: 66px;
  border-radius: 14px;
}

.investor-status-customer-not-registered-on-fundswala {
  background-color: #ffe4e4;
  color: #ec0404;
  border-radius: 14px;
}

.investor-action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.investor-action-buttons p {
  margin: 0;
  color: #007bff;
  cursor: pointer;
}

.investor-action-buttons p:hover {
  text-decoration: underline;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.two-line-text {
  display: block;
  /* max-width: 200px;  */
  overflow: hidden;
  white-space: normal;
  line-height: 1.4;
  max-height: calc(1.4em * 2);
}

.transaction-filter-td {
  display: flex;
  align-items: center;
}
