.goalCardMainBox {
  background-color: var(--primary-second);
  color: #ffff;
  border-radius: 12px;
}

.goalPlanTitle {
  font-family: Lato;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  white-space: nowrap;
  width: 5em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.goalCardSecondSection p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e8ecf1;
}

.goalCardSecondSection span {
  font-family: Lato;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.goalCardPaiBox {
  border-radius: 8px;
}

.goalCardPaiBoxTitle {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

.goalTime {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555555;
}

.goalPrice {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

.goalCardMainBox .card-header {
  padding: 20px;
  border-bottom: 1px solid #837bc5;
}

.goalCardMainBox .card-body {
  padding: 20px;
}

.goals-edit,
.goals-edit:hover {
  background: #dfdbff;
  display: flex;
  width: 25px;
  height: 25px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.goals-delete,
.goals-delete:hover {
  background: #ffdddd;
  display: flex;
  width: 25px;
  height: 25px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.goals-view,
.goals-view:hover {
  background: #aefff1;
  display: flex;
  width: 25px;
  height: 25px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.goalTopSideImage img {
  width: 25px;
  height: 25px;
}
@media (min-width: 400px) {
  .goalTopSideImage img {
    width: 40px;
    height: 40px;
  }
  .goalPlanTitle {
    font-family: Lato;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    white-space: nowrap;
    width: 8em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .goalPlanTitle {
    font-family: Lato;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    white-space: nowrap;
    width: 8em;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .goals-delete,
  .goals-edit,
  .goals-edit:hover,
  .goals-delete:hover {
    width: 32px;
    height: 32px;
  }
  .goals-view,
  .goals-edit,
  .goals-edit:hover,
  .goals-view:hover {
    width: 32px;
    height: 32px;
  }
}
@media (min-width: 992px) {
  .goalPlanTitle {
    font-family: Lato;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 19.2px;
    text-align: left;
    color: #ffffff;
    width: unset;
  }
}
/* Modal Styles */
.modal {
  display: none;
  position: fixed;
  /* z-index: 1050; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: flex; /* Show modal */
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-body {
  margin: 15px 0;
  font-size: 16px;
  color: #333;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer .btn {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.modal-footer .btn-secondary {
  background-color: #f0f0f0;
  color: #333;
}

.modal-footer .btn-danger {
  background-color: #d9534f;
  color: #fff;
}

.modal-footer .btn:hover {
  opacity: 0.9;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #3583ff !important; /* Thumb color */
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #3583ff !important; /* Thumb color */
  border-radius: 50%;
  cursor: pointer;
}
