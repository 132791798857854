/* funds card  */
.fund-card {
  border-radius: 12px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border: 1px solid #c0c3c9;
  height: 100%;
}

.fund-logo {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: #f0f0f0;
}


.fund-name {
  color: var(--dark-gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  white-space: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 160px;
}

.fund-card:hover .fund-name,
.fund-card:hover .fund-name {
  color: var(--primary-second);
}

.fund-details-right .bookmark-icon {
  filter: none;
}

.bookmarked .bookmark-icon {
  filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(3000%) hue-rotate(210deg) brightness(95%) contrast(100%);
}

.custom-badge {
  border-radius: 20px;
  padding: 5px 13px;
  font-family: Lato;
  font-size: 11px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  letter-spacing: 1px;
}

.green-badge {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.purple-badge {
  background-color: var(--light-purple);
  color: var(--primary-second);
}

.investment-details {
  font-family: Montserrat-m;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: var(--light-second-gray);
}

.investment-details span {
  font-family: Montserrat-m;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  color: var(--dark-gray);
}

.popup-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: 14px;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 1;
  }
}

@media (min-width: 576px) {
  /* .fund-name {
        width: 126px;
    } */

  .fund-name {
    color: var(--dark-gray);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 142px;
  }

  .new-fund-name {
    max-width: 220px;
  }
}

@media (min-width: 992px) {
  .fund-name {
    /* max-width: 150px; */
    font-size: 14px;
  }

  .fund-logo {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: #f0f0f0;
  }

  .new-fund-name {
    max-width: 296px;
  }
}

@media (min-width: 1024px) {
  .fund-name {
    max-width: 150px;
    font-size: 16px;
  }

  .new-fund-name {
    max-width: 215px;
  }

  .custom-badge {
    font-size: 13px;
  }

  .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
}

@media (min-width: 1300px) {
  .fund-name {
    max-width: 160px;
  }

  .new-fund-name {
    max-width: 255px;
  }
}

@media (min-width: 1400px) {
  .fund-name {
    max-width: 165px;
  }

  .new-fund-name {
    max-width: 296px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .new-fund-name {
    max-width: 180px;
  }
}

@media (max-width: 1194px) and (min-width: 1000px) {
  .new-fund-name {
    max-width: 288px;
  }
}



/* .new-fund-name {
  max-width: 100%;
} */