.invester-progress {
  background-color: #3583ff !important;
}

.invester-progress-bar {
  background-color: #007bff !important;
}

.border-top-rounded {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.step-heading {
  color: #3583ff;
  font-weight: 400;
  font-size: 14px;
}

.step-label {
  font-weight: 700;
  font-size: 18px;
  color: #2c2d3a;
}

.next-btn-right {
  margin-left: auto;
}

.nomine-custom-datepicker {
  position: sticky !important;
  width: 100%;
}

.invester-popup {
  max-width: 400px !important;
  background-color: #fff;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invester-modal-header {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding: 20px; */

  border-bottom: none !important;
  padding: 0px;
}

.invester-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 !important;
}

.invester-modal-body p {
  margin-top: 16px;
  font-size: 22px;
  color: #2c2d3a;
  font-weight: 600;
  text-align: center;
}

.invester-modal-icon {
  font-size: 60px;
  color: #00ac78;
}

.invester-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none !important;
  margin-bottom: 20px;
}

.invester-modal-footer .btn-primary {
  width: 200px;
  background-color: #3583ff;
  color: #fff;
  border-radius: 28px !important;
}

.invester-modal-footer .btn {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.mapping-details-box {
  padding: 20px 26px;
  border-radius: unset;
  /* border-radius: 12px; */
}

.investor-status-box {
  padding-top: 0px !important;
}

.mapping-details-no-border {
  border-bottom: none !important;
}

.header-purple {
  background-color: #dfdbff;
  padding: 20px 15px;
  border-radius: 8px;
}

.header-purple p {
  text-align: center;
  margin-bottom: 0;
}

.header-purple-span {
  color: #5a4ad5;
  font-weight: 700;
}

.generate-link-btn {
  background-color: #dfdbff;
  color: #5a4ad5;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
}

.investor-status-box {
  border: none !important;
  padding: 20px 26px;
}

.next-btn-status {
  width: 157px !important;
}

.investor-no-border {
  border: none !important;
}

.assign-customer-btn button:nth-child(1) {
  margin-top: 25px;
}
.assign-customer-btn button:nth-child(2) {
  margin-top: 30px;
}

.investor-num-call {
  color: #5a4ad5 !important;
}

.investor-call-cursor {
  cursor: pointer;
}

.manage-nominess-file {
  color: #a9a9a9 !important;
}

/* .investor-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .investor-btn-container {
    flex-direction: column;
    gap: 15px;
  }

  .investor-btn-container div {
    width: 100%;
    text-align: end;
  }
} */

.investor-btn-container {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  gap: 10px;
}

@media (max-width: 768px) {
  .investor-btn-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .investor-btn-container {
    /* grid-template-columns: 1fr;  */
    grid-template-columns: repeat(2, 0fr);
  }

  .investor-btn-p-10 {
    padding: 10px !important;
  }

  .investor-btn-p-6 {
    padding: 6px !important;
    border-radius: 18px !important;
  }

  .investment-heading-title {
    font-size: 18px !important;
    margin-right: 10px !important;
    margin-bottom: 0 !important;
  }

  .mapping-details-box1 {
    padding: 10px !important;
  }

  .mapping-details-body {
    margin-top: 17px;
  }

  .generate-link-btn {
    padding: 3px 5px;
  }

  .header-purple {
    text-align: start;
    font-size: 14px;
  }
}

@media (max-width: 388px) {
  .investor-btn-container {
    grid-template-columns: 1fr;
  }
}
