.redeam_bottom_card{
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #C0C3C9 !important;
 
}


.redeam_bottom_card {
    padding: 26px 26px;
}

.text_success{
    color:#00AC78;
}
.r_inner_title {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: var(--dark-gray);
}
.r_inner_title span{
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    margin-left: 10px;
}

.divider {
    display: none;
    width: 16px;
    vertical-align: super;
    transform: rotate(90deg);
    border: 1px solid #C0C3C9;
}

.border-bottom {
    border-bottom: 1px solid #e0e0e0; /* Subtle border */
}

.redeam_bottom_card .form-check-input[type=checkbox] {
    border: 2px solid #555555;
    width: 16px;
    height: 16px;
    border-radius: 2px;
}
.redeam_bottom_card .form-check-input[type=checkbox]:checked{
    border: 2px solid #3583FF;
background-color: #3583FF;
}

.form_card_border.card-footer{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

@media (min-width:576px) {
    .redeam_bottom_card .form-check-input[type=checkbox] {
        width: 18px;
        height: 18px;
    }
    .r_inner_title {
        font-size: 14px;
    }
    .r_inner_title span{
        font-size: 16px;
    }
    .divider {
        display: inline-block;
        width: 16px;
        vertical-align: super;
        transform: rotate(90deg);
        border: 1px solid #C0C3C9;
    }
}
@media (min-width:768px) {
    .divider {
        display: inline-block;
        width: 24px;
        vertical-align: super;
        transform: rotate(90deg);
        border: 1px solid #C0C3C9;
    }
    .r_inner_title {
        font-size: 12px;
    }
    .r_inner_title span{
        font-size: 14px;
        
    }
    .redeam_bottom_card .form-check-input[type=checkbox] {
        width: 18px;
        height: 18px;
    }
    .divider {
    display: inline-block;
    width: 20px;
    vertical-align: super;
    transform: rotate(90deg);
    border: 1px solid #C0C3C9;
}
}
@media (min-width:992px) {
    .divider {
        display: inline-block;
        width: 24px;
        vertical-align: super;
        transform: rotate(90deg);
        border: 1px solid #C0C3C9;
    }
    .r_inner_title {
        font-size: 15px;
    }
    .r_inner_title span{
        font-size: 18px;
        
    }
    .redeam_bottom_card {
        padding: 32px 32px;
    
    }
    
}
@media (min-width:1200px) {
    .divider {
        display: inline-block;
        width: 24px;
        vertical-align: super;
        transform: rotate(90deg);
        border: 1px solid #C0C3C9;
    }
    .r_inner_title {
        font-size: 16px;
    }
    .r_inner_title span{
        font-size: 19px;
        
    }
}