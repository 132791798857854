.tickets_Messages {
    border-bottom: 12px;
    background-color: #ffff;
    border-radius: 12px;
    padding: 20px;
}
.tickets_Messages_table{
    border-collapse: separate;
    border-spacing: 0px 15px;
}

.tickets_Messages_table th {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #555555;
    text-wrap: nowrap;
}
.tickets_Messages_table td {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2C2D3A;
    text-wrap: nowrap;
}


.tickets_Messages_table tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: unset;
    border-width: 0;

}

.tickets_Messages_table tbody td:first-child {
    border-top-left-radius: 12px; 
    border-bottom-left-radius: 12px;
}

.tickets_Messages_table tbody  td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.tickets_Messages_table thead tr th{
    padding: 0px 15px;
}
.tickets_Messages_table tbody tr td{
    padding: 15px;
}
tr.ticket_table td {
    background-color: #F5F5F5;
}

@media (min-width:768px){
    .tickets_Messages {
        border-bottom: 12px;
        background-color: #ffff;
        border-radius: 12px;
        padding: 20px 45px;
    }
    .tickets_Messages_table th {
        font-size: 15px;
    }
    .tickets_Messages_table td {
        font-size: 17px;
    }
    .tickets_Messages_table tbody tr td {
        padding: 28px;
    }
    .tickets_Messages_table thead tr th{
        padding: 0px 28px;
    }
    
}