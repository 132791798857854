/* Container Styling */
.ticket-details-container {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

/* Card with Ticket Details */
.ticket-details-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #ddd;
}

/* Label and Value Styling */
.ticket-details-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.ticket-label {
  font-weight: 600;
  color: #666;
}

.ticket-value {
  color: #000;
  text-align: right;
}

/* Action Buttons */
.ticket-actions {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

/* Cancel Button */
.ticket-cancel-btn {
  border: 2px solid #6c63ff;
  color: #6c63ff;
  font-weight: bold;
  border-radius: 25px;
  padding: 8px 24px;
  background: none;
  cursor: pointer;
}

.ticket-cancel-btn:hover {
  background-color: #f4f3ff;
}

/* Reply Button */
.ticket-reply-btn {
  background-color: #6c63ff;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 25px;
  padding: 8px 24px;
  cursor: pointer;
}

.ticket-reply-btn:hover {
  background-color: #5947e0;
}

/* 
.modal-header-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 20px;
}

.modal-icon {
  width: 50px; 
  height: 50px;
  margin-bottom: 10px;  
}

.modal-text {
  font-size: 18px;
  text-align: center;
  color: #333;
}

.modal-header-custom .close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;
}

.modal-footer {
  justify-content: center;
  padding: 20px;
} */



.custom-modal .modal-content {
  width: 357px;
  height: 288px;
  border-radius: 10px;
}

.modal-header-custom {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
}

.modal-icon {

  margin-bottom: 10px;
}

.modal-text {
  margin: 10px 0 0;
}

.modal-footer-custom {
  border: none;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-cancel-btn {
  border: 1px solid #6c757d;
  color: #6c757d;
  border-radius: 5px;
  width: 90px;
}

.modal-confirm-btn {
  background-color: #007bff;
  border-radius: 5px;
  width: 90px;
}