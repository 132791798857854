/* funds card  */
.card-bg-primary{
    background: var(--primary-second);
}
.card-bg-primary2{
    background: #3583FF;
}
.card-text-white{
    background: var(--primary-second);
}
.fund-card-three {
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #C0C3C9
  }
  
  .fund-card-three  .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}
  
.fund-card-three .fund-name-header {
    color: var(--light-white);
    font-size: 16px;
    line-height: 19.2px;
    text-align: left;
    width: 100%;
    font-family: 'Lato';
    cursor: pointer;
}

.fund-card-three:hover .fund-name-header ,.fund-card-three:hover .fund-card-three .fund-name-header {
    color: var(--light-white);
}

.fund-details-right .bookmark-icon {
    filter: none;
}
.bookmarked .bookmark-icon {
    filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(3000%) hue-rotate(210deg) brightness(95%) contrast(100%);
  }

  .fund-card-three .custom-badge {
    border-radius: 20px;
    padding: 5px 13px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    letter-spacing: 0px;
}
.orange-badge{
    background-color: #FADBBE;
    color: #F5820A;
}
.green-badge{
    background-color: var(--light-green);
    color: var(--dark-green);
}
.purple-badge{
    background-color: var(--light-purple);
    color: var(--primary-second);
}

.fund-card-three  .investment-details {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #EDF2FF;
    letter-spacing: 0.4px;
}

.fund-card-three  .investment-details span {
    font-size: 17px;
    /* font-weight: 700; */
    line-height: 21.6px;
    text-align: left;
    color: var(--light-white);
    margin-top: 5px;
    font-family: 'Lato-bold';
}

.fund-card-three .card-body {
    display: flex;
    row-gap: 13px;
    flex-direction: column;
}

a.view_all_btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: right;
    color: var(--light-blue-color);
}

.custom_dropdown {
    position: absolute;
    top: -14px;
    right: 0;
    padding: 14px;
}
@media (min-width:576px) {
    .custom_dropdown{
        position: unset;
        top: 0;
        padding: 0px;
    }
}
@media (min-width:768px) {
    .fund-card-three .card-body {
        display: grid;
        grid-template-columns: auto auto;
    }
    
}

@media (min-width:992px) {
    .fund-card-three .card-body {
        display: grid;
        grid-template-columns: 13% 85%;
    }
}

@media (min-width:1200px) {
    .fund-card-three .card-body {
        display: grid;
        grid-template-columns: 10% 89%;
    }
}
@media (min-width:1300px) {
    .fund-card-three .card-body {
        display: grid;
        grid-template-columns: 9% 90%;
    }
}

@media (min-width:1400px) {
    .fund-card-three .card-body {
        display: grid;
        grid-template-columns: 7% 92%;
    }
}