/* fund section  */

.start_investment_box {
  background: linear-gradient(90deg, #5a4ad5 0%, #3583ff 100%);
  border-radius: 12px !important;
  padding: 20px 20px;
  margin-bottom: 30px;
}

.headerBackground {
  background: linear-gradient(90deg, #3583ff 0%, #5a4ad5 100%) !important;
}
.start_investment_box .investment-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.gross_percent {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.start_investment_box .investment-value-header {
  font-family: "Lato";
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 24px;
  text-align: left;
  color: #edf2ff;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.start_investment_box .value-text {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  letter-spacing: 1px;
}
.start_investment_box .page-title {
  font-family: Lato;
  font-size: 25px;
  font-weight: 700;
  line-height: 38.2px;
  text-align: left;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.start_investment_box .custom-investment-info {
  gap: 186px !important;
}

@media (min-width: 425px) {
}

@media (max-width: 576px) {
  .start_investment_box .custom-investment-info {
    gap: 80px !important;
  }
}

@media (min-width: 576px) {
  .start_investment_box {
    padding: 28px 28px;
    margin-bottom: 35px;
  }
  .start_investment_box .investment-info {
    gap: 28px;
  }

  .start_investment_box .custom-investment-info {
    gap: 50px !important;
  }
  .start_investment_box .page-title {
    font-size: 30px;
    line-height: 40.2px;
    margin-bottom: 28px;
  }
}

@media (min-width: 768px) {
  .start_investment_box {
    padding: 32px 32px;
    margin-bottom: 35px;
  }
  .start_investment_box .investment-info {
    gap: 35px;
  }
  .start_investment_box .custom-investment-info {
    gap: 50px !important;
  }
  .start_investment_box .value-text {
    font-size: 22px;
    line-height: 30px;
  }
  .start_investment_box .investment-value-header {
    margin-bottom: 6px;
  }
}

@media (min-width: 992px) {
  .start_investment_box .value-text {
    font-size: 24px;
  }
  .start_investment_box .investment-info {
    gap: 50px;
  }
  .start_investment_box .custom-investment-info {
    gap: 130px !important;
  }
  .start_investment_box .page-title {
    font-size: 36px;
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
  .start_investment_box .investment-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 85px;
  }
  .start_investment_box .custom-investment-info {
    gap: 186px !important;
  }
  .start_investment_box {
    padding: 34px 34px;
    margin-bottom: 35px;
  }
}
