.cart-bottom {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 21px;
    justify-content: end;
}
.cart-bottom p {
font-family: Lato;
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
text-align: right;
color: var(--dark-gray);
}
.cart-bottom p span{
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: right;  
color: var(--dark-gray);
}
.cart-bottom  .form_button.next_btn {
    width: unset;
    padding: 20px 26px;
    border: unset;
}


/* modal design  */
.modal_card_inner .form-lable {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
    text-wrap: nowrap;
    margin-bottom: 8px;
}

.modal_card_inner.form-control {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
    background: #FFFFFF;
}

.eNach_middle_part {
    background-color: #EAE7FF;
    border-radius: 8px;
    padding: 14px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.modal_card_inner .modal_body, .modal_card_inner .modal-header {
    padding: 20px;
    border-bottom: 2px solid #EDEDED;
}
.modal_card_inner .modal-title {
    margin-bottom: 0;
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--dark-gray);
}
.modal_card_inner .modal-dialog{
    max-width: 645px;
}
.modal_card_inner .sip-modal{
    width: 800px;
}

.eNach_middle_part input[type="radio"], .sip_input[type="radio"]  {
    width: 20px;
    height: 20px;
    margin-right: 0.6rem;
}
.bank_image {
    width: 24px;
    height: 24px;
    padding: 0px !important;
    margin-right: 10px;
}
.sip_modaltable th, .sip_modaltable td {
    text-align: left;
    vertical-align: middle;
    padding: 3px 20px;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    color: var(--dark-gray);
    text-wrap: nowrap;
}

.sip_modaltable td:first-child {
    width: 10%;
}

.form_label {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--dark-gray);
}
.form_label a{
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color:var(--light-blue-color);
}
.modal_body .otp-input {
    width: 30px;
    height: 25px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: var(--dark-gray);
    border-inline: unset;
    border-top: unset;
    border-bottom: 2px solid #2C2D3A;
    background: transparent;
}


.modal_card_inner .modal-footer {
    padding: 20px 20px;
}

.verify_button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border-radius: 28px;
    color: #FFFFFF;
    height: 35px;
    border: transparent;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.verified_button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: #BBFFEA;
    border-radius: 8px;
    color: #00AC78;
    height: 35px;
    border: transparent;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media (min-width:576px) {
    .modal_body .otp-input {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }
}

@media (min-width:768px) {
    .modal_body .otp-input {
        width: 40px;
        height: 30px;
    }
    .modal_card_inner .modal_body, .modal_card_inner .modal-header {
        padding: 25px;
        border-bottom: 2px solid #EDEDED;
    }
    
.modal_card_inner .modal-footer {
    padding: 20px 20px;
}
.modal_card_inner .modal-title {
    font-size: 20px;
}
.verify_button {
    font-size: 16px;
    height: 40px;
}
.verified_button {
    font-size: 16px;
    height: 40px;
}
.modal_body .otp-input {
    width: 40px;
    height: 40px;
    font-size: 16px;
}
.form_label {
    font-size: 20px;
}
.form_label a{
    font-size: 16px;
}
}
@media (min-width:1200px) {
    .modal_body .otp-input {
        width: 40px;
        height: 40px;
    }
    .modal_card_inner .modal-footer {
        padding: 30px 20px;
    }
}

@media (min-width:1300px) {

}

@media (min-width:1400px) {}

  