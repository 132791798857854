img.rounded-circle.me-3.order_logo {
    height: 56px;
    width: 55.71px;
}

.outerHeading {
    font-family: lato;
    font-size: 17px;
    line-height: 19.2px;
    font-weight: 600;
    color: var(--dark-gray);
}

.transaction_inner {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 16.8px;
    color: #555555;
    font-weight: 400;
}

.transaction_inner_value {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 19.2px;
    color: #2C2D3A;
    font-weight: 700;
    margin-top: 10px;
}

.text_success {
    color: #00AC78;
}


.aditya_currency2 {
    font-family: lato-bold;
    font-size: 24px;
    line-height: 28.8px;
    color: #00AC78;
}

.aditya_currency_time {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 16.8px;
    color: #2C2D3A;
}

.order_box:hover .outerHeading,
.order_box:hover .order_box .outerHeading {
    color: #5A4AD5;
    cursor: pointer;
}

.transaction_content2 {
    margin-left: 70px;
}


@media (max-width: 576px) {
    .currency2 {
        /* margin-left: 68px; */
        text-align: start !important;
        margin-bottom: 10px;
    }

    img.rounded-circle.me-3.order_logo {
        height: 42px;
        width: 38.71px;
    }

    .outerHeading {
        font-size: 14px;
    }

    .aditya_currency {
        font-size: 19px;
    }
}

@media (max-width: 567px) {
    .transaction_content2 {
        margin-left: 0px;
    }
}

@media (max-width: 410px) {
    .transaction_content2 {
        margin-left: 0px;
    }

    .currency2 {
        margin-left: 3px;
    }
}