.faq_card {
  border-bottom: 12px;
  background-color: #ffff;
  border-radius: 12px;
  padding: 20px;
}

.profile_accordian .accordion-item {
  margin-bottom: 14px;
}

.profile_header button {
  font-family: Lato-bold;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
  background-color: #fff;
}

.profile_accordion_body {
  font-family: Lato;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555555;
  border-bottom: 0px !important;
}

.profile_accordian .accordion-button:not(.collapsed) {
  color: #2c2d3a;
  background-color: unset;
  box-shadow: unset;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset;
}

.profile_accordian .accordion-item {
  color: var(--bs-accordion-color);
  background-color: #fff;
  border: unset;
  border-radius: 12px;
}

.profile_accordian .accordion-item .accordion-header .accordion-button {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-radius: 12px;
  /* padding: 22px; */
  height: 77px;
}
/* .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: ;
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
} */

@media (max-width: 992px) {
  .profile_header button {
    font-size: 1rem;
  }

  .profile_accordion_body {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .profile_header button {
    font-size: 0.875rem;
  }

  .profile_accordion_body {
    font-size: 0.8125rem;
  }
}

@media (max-width: 576px) {
  .profile_header button {
    font-size: 0.9rem;
  }

  .profile_accordion_body {
    font-size: 0.8rem;
  }
}

/* vishal  */
.profile_accordian .personel_details_img {
  margin-right: 10px;
}
.profile_accordian .personal_inner_text {
  font-family: Lato;
  font-size: 18px;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 8px;
}

.profile_accordian .fund-card-sip .custom-badge {
  border-radius: 8px;
  padding: 5px 13px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  letter-spacing: 0px;
}
.profile_accordian .editBadge,
.profile_accordian .add-button {
  gap: 10px;
  border-radius: 8px;
  background: #dfdbff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin-right: 44px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #5a4ad5;
}

.profile_accordian .form-check {
  display: flex;
  align-items: center;
  gap: 3px;
}

.profile_accordian .pen_image {
  width: 16px;
  height: 16px;
}

.button.accordion-button.collapsed {
  background-color: #fff;
}

.profile_accordian .card.redeam_bottom_card {
  background-color: white;
  border-bottom: 0px !important;
}

.personalAccordian {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

.bankDetailTable table thead tr th {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555555;
  padding: 15px 20px;
}

.bankDetailTable tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
}
.bankDetailTable table tbody tr td {
  background-color: #f5f5f5 !important;
  color: #2c2d3a;
  padding: 18px 20px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
}

.bankDetailTable td,
th {
  text-wrap: nowrap;
}

.bankDetailTable tbody tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.bankDetailTable tbody tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.bankDetailTable td:first-child {
  padding-left: 33px;
}
.bankDetailTable td:last-child {
  padding-right: 33px;
  text-align: end;
}

.bankDetailTable thead tr th:last-child {
  text-align: end;
}

.bank_input[type="radio"] {
  width: 20px;
  height: 20px;
}
/* .details_edit_btn{
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-color: #DFDBFF;
        border-radius: 4px;
    } */
.details_edit_btn img {
  width: 32px;
  height: 32px;
}

.add_bank_detail_text {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

.manageNomineesDetailTable table thead tr th {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555555;
  padding: 17px 20px;
  text-wrap: nowrap;
}

.manageNomineesDetailTable tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
}

.manageNomineesDetailTable table tbody tr td {
  background-color: #f5f5f5 !important;
  color: #2c2d3a;
  text-align: left;
  padding: 18px 20px;
  border: none;
  text-wrap: nowrap;
  /* font-weight: 600; */
  font-family: Lato;
}

.manageNomineesDetailTable table tbody tr {
  border-radius: 10px;
  margin-bottom: 10px;
  display: table-row;
  transition: background-color 0.3s ease;
}

.manageNomineesDetailTable table tbody tr:hover {
  background-color: #eaeaea;
}

.manageNomineesDetailTable tbody tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.manageNomineesDetailTable tbody tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.noteNominee {
  padding: 12px 12px 12px 12px;
  gap: 10px;
  border-radius: 4px;
  background-color: #dfdbff;
  color: #5a4ad5;
  width: 60%;
}
.noteHeading {
  font-weight: 700;
}
.firstNominee {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.addMore {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #5a4ad5;
}

.card-footer.form_card.form_card_border {
  border: none;
}

.bankDetailHeading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

.pen_bank_inner {
  background: #5a4ad5;
}
.auto_pay_inner {
  background: #3583ff;
}
.map_ac_inner {
  background: #34d3b7;
}

.set_auto_pay_same_content {
  width: 32px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pen_image_inner_bank {
  /* width: 16px;
        height: 16px;
        top: 4px;
        left: 8px;
        gap: 0px;
        position: relative; */
}
.bankInfo {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2d3a;
}

@media (max-width: 768px) {
  .profile_accordian .personal_inner_text {
    font-family: Lato;
    font-size: 16px;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-left: 8px;
  }
  .profile_accordian .editBadge,
  .profile_accordian .add-button {
    gap: 6px;
    border-radius: 8px;
    background: #dfdbff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin-right: 40px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5a4ad5;
  }
  .bankDetailTable td:last-child {
    padding-right: 19px;
    text-align: end;
  }
  .bankDetailTable td:first-child {
    padding-left: 19px;
  }
  .add_bank_detail_text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2c2d3a;
  }
  .calendericon img {
    vertical-align: -webkit-baseline-middle;
    background-color: #fff;
    padding: 2px 2px;
    color: #d0d0d0;
    width: 23px;
    height: 30px;
  }
}

@media (max-width: 575px) {
  .profile_accordian .accordion-item .accordion-header .accordion-button {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-radius: 12px;
    /* padding: 22px; */
    height: 60px;
  }
  .profile_accordian .personal_inner_text {
    font-family: Lato;
    font-size: 14px;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-left: 8px;
  }
  .profile_accordian .editBadge,
  .profile_accordian .add-button {
    gap: 6px;
    border-radius: 8px;
    background: #dfdbff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 9px;
    margin-right: 24px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5a4ad5;
  }
  .profile_accordian .personel_details_img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .profile_accordian .card.redeam_bottom_card {
    background-color: white;
    border-bottom: 0px !important;
    padding: 10px;
  }
  .bankDetailTable table tbody tr td {
    background-color: #f5f5f5 !important;
    color: #2c2d3a;
    padding: 7px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
  }
  .bankDetailTable table thead tr th {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #555555;
    padding: 11px 10px;
  }
  .add_bank_detail_text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2c2d3a;
  }
  .noteNominee {
    padding: 12px 12px 12px 12px;
    gap: 10px;
    border-radius: 4px;
    background-color: #dfdbff;
    color: #5a4ad5;
    width: 100%;
  }
  .calendericon img {
    vertical-align: -webkit-baseline-middle;
    background-color: #fff;
    padding: 2px 2px;
    color: #d0d0d0;
    width: 21px;
    height: 30px;
  }

  .accordion-body1 {
    padding: 0;
  }

  .accordion-from-label {
    font-weight: 600;
  }
}
