/* select dropdown css */
.custom-select2 {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: none; */
}

.custom-select2-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  width: 20px;
  height: 20px;
}

.custom-select2::-ms-expand {
  display: none; /* Removes default arrow in IE and Edge */
}

.custom-select2-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.custom-select2 option {
  font-size: 14px !important;
  color: var(--dark-gray);
}

.custom-select2:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}
/* select dropdown css end*/

.search-container {
  margin-top: 30px;
}

.search-input-custom {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  padding-left: 2rem;
  width: 100%;
}

.search-icon-custom {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.form-select-custom {
  background-color: white;
  border: 1px solid #dee2e6;
  cursor: pointer;
  height: 38px;
}

.dropdown-label-custom {
  font-size: 0.875rem;
  color: #555555;
  margin-bottom: 12px;
}
