.disclaimer-box {
    background-color: #1A124A !important;
    color: #FFFFFF;
    border: 2px solid #2E2366 !important;
    border-radius: 12px;
}
/* .disclaimer_image{
height: 190px;
    width: 120px;
} */
.disclaimer_image img {
    height: 150px;
    width: 113px;
}
.disclaimer-text {
    font-size: 15px;
    line-height: 19.2px;
    text-align: justify;
    color: #E8ECF1;
    font-family: 'Lato';
}

@media (min-width: 768px) {

.disclaimer-text {
    font-size: 17px;
    line-height: 20.2px;
}
.disclaimer_image img {
    height: 200px;
    width: 113px;
}
}
.dashboardSectionBox{
    margin-bottom: 35px;
}

.custom-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 10;
}

.custom-prev-btn,
.custom-next-btn {
    background: none;
    border: none;
    color: #555555;
    font-size: 24px;
    cursor: pointer;
}

.custom-prev-btn:hover,
.custom-next-btn:hover {
    color: #555555;
}

.custom-prev-btn {
    margin-left: 10px;
}

.custom-next-btn {
    margin-right: 10px;
}
