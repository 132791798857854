.otp-generate {
  color: #5A4AD5;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  cursor: pointer;
}

.outer_pop_up{ 
    padding: 20px;
    text-align: center
}


.outer_pop_up .modelButton{
        padding: 10px 20px;
        background: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      
.outer_pop_up .main_model_view{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 45px 30px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        width: 350px;
        text-align: center;
        z-index: 1000;
      } 
      
.outer_pop_up .inner_close_model{
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 25px; 
        padding: 2px 18px;
      } 
      
.outer_pop_up .successLogo{    
            margin: 0 auto 20px;
      } 

.outer_pop_up .ok_button_inner{
        margin-top: 5px;
        width: 100%;
        padding: 10px 20px;
        background: linear-gradient(to right, #3583FF, #204F99);
        color: #fff;
        border: none;
        border-radius: 28px; 
      }

      
.outer_pop_up .extraInnerDiv{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000080;  
        z-index: 999,
      }

      
.outer_pop_up h2.successText {
        font-family: 'Lato-bold';
        font-size: 20px;
        line-height: 28.8px;
        color: #2C2D3A;
    }

    .outer_pop_up h2.successEmailText {
        font-family: 'Lato-bold';
        font-size: 14px;
        line-height: 16.2px;
        color: #2C2D3A;
    }


    .outer_pop_up .modal-content{
      border: 12px;
  }
.red-color {
    color: red;
}
@media (min-width:768px){
.outer_pop_up .modal-content{
    width: 388px;
    margin: 0 auto;
}
.outer_pop_up h2.successText {
  font-family: 'Lato-bold';
  font-size: 24px;
  line-height: 28.8px;
  color: #2C2D3A;
}
.outer_pop_up h2.successEmailText {
  font-family: 'Lato-bold';
  font-size: 16px;
  line-height: 19.2px;
  color: #2C2D3A;
}

}