#downloads_Plan_section .card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    height: 100%;
}

#downloads_Plan_section .card:hover {
    transform: translateY(-5px);
}
#downloads_Plan_section .card:hover .card_title{
color:#5A4AD5;
}
#downloads_Plan_section .container {
    padding-top: 20px;
}

#downloads_Plan_section .card img {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
}

#downloads_Plan_section .card-body {
    text-align: center;
}

/* plans section  */

#downloads_Plan_section {
    background-color: var(--light-gray);
    padding: 45px 40px;
}

.downloads_Plan_section .section-title {
    color: var(--dark-gray);
}

.downloads_Plan_section .section-title span {
    color: var(--light-blue-color);
}
  
  
/* end plan section  */

#downloads_Plan_section h5.card_title {
    color: var(--dark-gray);
    font-size: 16px;
    line-height: 24px;
}

#downloads_Plan_section #amcSelect{
    background-color: #EDEDED;
    border: 1px solid #C0C3C9;
    color: #A0A0A0;
}

#downloads_Plan_section .downloads-form-label{
    color: var(--dark-gray);
    font-size: 16px;
    line-height: 19.2px;
}

#downloads_Plan_section .input-group-text {
    background-color: #EDEDED;
    border: 1px solid #C0C3C9;
    border-right: none;
    padding-right: 0;
}

#downloads_Plan_section .form-select {
    border-left: none !important;
}
#downloads_Plan_section .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2.5rem; /* Space for custom icon */
    background: none; /* Removes default background */
    border: 1px solid #ced4da;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem; /* Keep right side border-radius */
    border-bottom-right-radius: 0.375rem; /* Keep right side border-radius */
    padding-left: 40px;
}
#downloads_Plan_section .form_search {
    position: absolute;
    left: 0px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}


#downloads_Plan_section .custom-select-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    width: 20px;
    height: 20px;
}

#downloads_Plan_section .custom-select::-ms-expand {
    display: none; /* Removes default arrow in IE and Edge */
}

#downloads_Plan_section .custom-select-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
#downloads_Plan_section .custom-select option {
    color: var(--dark-gray);
}

#downloads_Plan_section .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: unset;
}

@media (max-width:768px){
    #downloads_Plan_section .custom-select option {
        font-size: 14px;
        color: var(--dark-gray);
    }
    #downloads_Plan_section {
        background-color: var(--light-gray);
        padding: 40px 20px;
    }
}