/* Background color for modal header */
.modal-header-custom {
  background-color: #fef3f3;
  border-bottom: none;
}

/* Icon style for modal */
.modal-icon {
  color: #ff4d4f; /* Bright red color for warning icon */
  font-size: 2rem;
  margin-right: 8px;
}

/* Modal body text style */
.modal-body-text {
  font-size: 1rem;
  color: #555;
  margin-top: 8px;
}

/* Cancel button in the modal */
.modal-cancel-btn {
  border-color: #d9d9d9;
  color: #555;
  background-color: white;
  font-weight: 600;
  padding: 8px 16px;
}

/* OK button in the modal */
.modal-confirm-btn {
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
  font-weight: 600;
  padding: 8px 16px;
}

/* Center footer buttons */
.modal-footer {
  justify-content: center;
}

/* Table row custom style */
.custom-tbody tr {
  background-color: #f9f9f9; /* Light grey for rows */
}

/* Custom table hover effect */
.custom-tbody tr:hover {
  background-color: #f3f3f3; /* Slightly darker grey */
}
