/* fund section  */


.my_profile_header {
    background: linear-gradient(90deg, #5A4AD5 0%, #3583FF 100%);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
 
    padding: 20px 20px;
  
}
.my_profile_header .success_message{
    color: #00FFB2;
    font-family: Lato;
    font-weight: 400;
    line-height: 19.8px;
    font-size: 14px;
}

.my_profile_header .error_message{
    color: #f55d46;
    font-family: Lato;
    font-weight: 400;
    line-height: 19.8px;
    font-size: 14px;
}
.my_profile_header .investment-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.gross_percent {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.my_profile_header .investment-value-header {
    font-family: 'Lato';
    font-size: 13px;
    line-height: 14px;
    text-align: left;
    color: #EDF2FF;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.my_profile_header .value-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.my_profile_header .page-title {
    font-family: Lato;
    font-size: 25px;
    font-weight: 700;
    line-height: 38.2px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

@media (min-width:425px) {}

@media (min-width:576px) {
    .my_profile_header {
        padding: 28px 28px;
    }

    .my_profile_header .investment-info {
        gap: 28px;
    }

    .my_profile_header .page-title {
        font-size: 30px;
        line-height: 40.2px;
        margin-bottom: 28px;
    }
    .my_profile_header .investment-value-header {
        font-family: 'Lato';
        font-size: 14px;
        line-height: 24px;
    }
    .my_profile_header .value-text {
        font-size: 18px;
    }
}

@media (min-width:768px) {
    .my_profile_header {
        padding: 32px 32px;
       
    }

    .my_profile_header .investment-info {
        gap: 45px;
    }

    .my_profile_header .value-text {
        font-size: 22px;
        line-height: 30px;
    }

    .my_profile_header .investment-value-header {
        margin-bottom: 6px;
    }
    .my_profile_header .investment-value-header {
        font-family: 'Lato';
        font-size: 16px;
        line-height: 24px;
    }
    .my_profile_header .value-text {
        font-size: 20px;
    }

}

@media (min-width:992px) {
    .my_profile_header .value-text {
        font-size: 24px;
    }

    .my_profile_header .investment-info {
        gap: 50px;
    }

    .my_profile_header .page-title {
        font-size: 36px;
        margin-bottom: 35px;
    }
}

@media (min-width:1200px) {}

@media (min-width:1400px) {
    .my_profile_header .investment-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 65px;
    }

    .my_profile_header {
        padding: 34px 34px;
    }
}