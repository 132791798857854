/* invest section  */
#about_mission_section {
    padding: 35px 15px;
    background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
}

.about_mission_section .section-title {
    color: #FFFFFF;
    text-align: left;
}

.about_mission_section .fund_invest_text {
    text-align: left;
    color: var(--light-gray);
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}

.fund_invest_box_two {
    height: 70px;
    width: 80px;

}

.fund_invest_box_two {
    bottom: 0;
    right: 0;
    top: -18px;
}

@media (min-width:425px) {

    .fund_invest_box_two {
        height: 76px;
        width: 80px;
    }

    .fund_invest_box_two {
        bottom: 0;
        right: 0;
        top: -26px;
    }
}

@media (min-width:576px) {
    #about_mission_section {
        padding: 80px 40px;
    }

    .fund_invest_box_two {
        height: 100px;
        width: 150px;
    }

    .fund_invest_box_two {
        bottom: 0;
        right: 0;
        top: -48px;
    }
}

@media (min-width:768px) {

    .fund_invest_box_two {
        height: 100px;
        width: 150px;
    }

    .fund_invest_box_two {
        bottom: 0;
        right: 0;
        top: -48px;
    }
    #about_mission_section {
        padding: 80px 40px;
    }

}

@media (min-width:992px) {
    #about_mission_section {
        padding: 65px 40px;
    }

    .fund_invest_box_two {
        bottom: -65px;
        right: 0;
        top: unset;

    }

    .fund_invest_box_two {
        height: 300px;
        width: 400px;
    }


}

@media (min-width:1200px) {
    #about_mission_section {
        padding: 80px 40px;
    }

    .fund_invest_box_two {
        bottom: -80px;
        right: 0;

    }

}

@media (min-width:1400px) {
    .fund_invest_box_two {
        bottom: -80px;
        right: 0;

    }

    .fund_invest_box_two {
        height: 350px;
        width: 450px;
    }

}

/* end second section  */
/* third sectiion */
#about_third_section {
    padding: 45px 15px;
    background: var(--primary-color);
}

.about_third_section.section-description {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.6px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    margin: 0 auto;
}

.about_dashbord_img {
    height: auto;
    width: 100%;
    /* border: 11px solid #F5F5F5;
    border-radius: 20px;
    object-fit: cover; */
}

.value-icon span {
    width: 40px;
    height: 40px;
    background: var(--light-blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
/* .value-icon span img {
    width: 24px;
    height: 24px;
} */

.value-title {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.value-description {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--light-gray);
    letter-spacing: 0.5px;
}

.start_journey_box {
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border-radius: 12px;
    padding: 20px 20px;
    margin-top: 43px;
}

.start_journey_box .heading {
    font-family: Lato;
    font-size: 17px;
    font-weight: 600;
    line-height: 25.4px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 2px;
    margin-bottom: 12px;
}

.start_journey_box .description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--light-gray);
    letter-spacing: 0.5px;
}

#about_third_section p.section-description.mb-3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #E8ECF1;
    font-family: Lato;
    text-align: center;
}


@media (min-width:425px) {}

@media (min-width:576px) {
    #about_third_section {
        padding: 80px 40px;
    }

    .about_dashbord_img {
        height: 400px;
        width: 450px;
    }

    .start_journey_box .heading {
        font-size: 25px;
        line-height: 36.4px;
        margin-bottom: 30px
    }

    .start_journey_box .description {
        font-size: 15px;
        line-height: 20px;
    }
    .value-title {
        font-size: 20px;
    }
    .start_journey_box {
        padding: 32px 32px;
    }
    .start_journey_box .heading {
        font-size: 22px;
        font-weight: 600;
        line-height: 34.4px;
        margin-bottom: 20px;
    }
}

@media (min-width:768px) {
    .about_third_section.section-description {
        width: 550px;
        font-size: 18px;
        line-height: 21.6px;
    }

    .start_journey_box {
        margin-top: 43px;
    }

    .start_journey_box .heading {
        font-size: 28px;
        line-height: 36.4px;
    }

    .start_journey_box .description {
        font-size: 16px;
        line-height: 23px;
    }
    #about_third_section {
        padding: 80px 40px;
    }
}

@media (min-width:992px) {
    #about_third_section {
        padding: 65px 40px;
    }

    .about_dashbord_img {
        height: auto;
        width: 374px;
    }

    .start_journey_box .heading {
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 35px
    }

    .start_journey_box .description {
        font-size: 18px;
        line-height: 27px;
        width: 700px;
        margin: 0 auto;
    }

    .start_journey_box {
        margin-top: 90px;
        padding: 58px 32px;
    }
}

@media (min-width:1200px) {
    #about_third_section {
        padding: 80px 40px;
    }

    .about_dashbord_img {
        height: auto;
        width: 470px;
    }
}

@media (min-width:1400px) {
    .about_dashbord_img {
        height: 500px;
        width: 550px;

    }
}

/* end third sectiion */