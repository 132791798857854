.faq_card {
    border-bottom: 12px;
    background-color: #ffff;
    border-radius: 12px;
    padding: 20px;
}

.faq_accordian .accordion-item {
    margin-bottom: 14px;
}

.faq_header button {
    font-family: Lato-bold;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2C2D3A;
    background-color: #F5F5F5;
    padding: 20px 20px;
}

.faq_accordion_body {
    font-family: Lato;
    font-size: 1rem;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #555555;
padding: 14px 20px 20px 20px;
}

.faq_accordian .accordion-button:not(.collapsed) {
    color: #2C2D3A;
    background-color: unset;
    box-shadow: unset;
}


.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: unset;
}

.faq_accordian .accordion-item {
    color: var(--bs-accordion-color);
    background-color: #F5F5F5;
    border: unset;
    border-radius: 12px;
}

.faq_accordian .accordion-item .accordion-header .accordion-button {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-radius: 12px;
}
.faq_accordian .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}


/* Remove the default icon */
.accordion-button::after {
    display: none;
}

/* Add custom icons */
.accordion-button {
    position: relative;
    padding-right: 2.5rem; /* Adjust space for custom icon */
}

.accordion-button::before {
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900; 
    content: '\f106'; 
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.accordion-button.collapsed::before {
    content: '\f107'; 
}


@media (max-width: 992px) {


    .faq_header button {
        font-size: 1rem;

    }

    .faq_accordion_body {
        font-size: 0.875rem;

    }
}

@media (max-width: 768px) {


    .faq_header button {
        font-size: 0.875rem;

    }

    .faq_accordion_body {
        font-size: 0.8125rem;

    }
}

@media (max-width: 576px) {


    .faq_header button {
        font-size: 0.9rem;
    
    }

    .faq_accordion_body {
        font-size: 0.8rem;
   
    }
}