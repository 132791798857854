.profile-mr {
  margin-right: 0 !important;
}

.addbank-btn-mb {
  margin-bottom: 20px;
}

.loader-p-2 {
  padding: 10px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-space-nowrap {
  white-space: nowrap;
}

.filter-checkbox-pt {
  padding-top: 8px;
}

.close-popup-color {
  color: #2c2d3a;
  font-size: 21px;
  cursor: pointer;
}

.ac-deactivated-box-bg {
  background: #231b5e !important;
  border-radius: 12px !important;
  padding: 40px 20px;
  margin-bottom: 30px;
}

.account-deactivated-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.account-deactivated-box img {
  height: 56px;
  width: 56px;
}

.deactivated-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.deactivated-btn {
  background: #ffdddd;
  color: #ff0000;
  border: none;
  border-radius: 24px;
  padding: 4px 12px 4px 12px;
  font-size: 14px;
}

.deactivated-title {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
}

.deactivated-subtitle {
  font-size: 18px;
  color: #ffffff;
}

.deactivated-subtitle span {
  font-weight: 800;
}

/* Hide the default password toggle icon in Microsoft Edge */
input::-ms-reveal {
  display: none;
}

.transaction-width {
  width: 200px !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: none; */
  background-color: #ffffff;
}

.white-space-normal {
  white-space: normal !important;
}

.mr-1 {
  margin-right: 10px;
}

.status-col {
  max-width: 170px;
}

tr.total_row td {
  font-weight: bold;
  background-color: #e6e6ff !important;
}

.chart-container {
  width: 100%;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto;
  /* Allow horizontal scroll */
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .chart-container {
    width: 100%;
    /* Full width for mobile */
    overflow-x: auto;
    /* Enable horizontal scrolling */
  }

  .invest_btn {
    line-height: 22.6px !important;
  }

  .recharts-responsive-container {
    min-width: 1200px;
    /* Set a fixed width for the chart */
    max-width: none;
  }
}

/* Container for the entire dropdown */
.dropdown-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
}

/* Dropdown column */
.dropdown-column {
  width: 100%;
  max-width: 300px;
}

.custom-dropdown-wrapper {
  position: relative;
  width: 100%;
}

.custom-dropdown-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  appearance: none;
  cursor: pointer;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease-in-out;
}

.custom-dropdown-select:focus {
  outline: none;
}

.dropdown-arrow svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 18px;
  /* Adjust arrow color */
}

.eye-icon {
  font-size: 25px;
  color: gray;
}

dual-listbox {
  display: flex;
  align-items: center;
  gap: 2rem;
  /* Space between listboxes and controls */
}

.listbox {
  display: flex;
  flex-direction: column;
}

.listbox label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 14px;
  /* Adjust font size for label */
}

.listbox select {
  width: 220px;
  /* Width of the listbox */
  height: 200px;
  /* Height of the listbox */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 14px;
  /* Font size for listbox items */
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* Space between buttons */
}

.controls button {
  width: 40px;
  /* Width of the button */
  height: 40px;
  /* Height of the button */
  border: none;
  border-radius: 4px;
  font-size: 18px;
  /* Adjust font size for the arrow */
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-btn {
  background-color: #e0f7fa;
  color: #00796b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Add slight shadow */
}

.unassign-btn {
  background-color: #ffebee;
  color: #d32f2f;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Add slight shadow */
}

.assign-btn:hover {
  background-color: #b2ebf2;
}

.unassign-btn:hover {
  background-color: #ffcdd2;
}

.justify-content-spacearound {
  justify-content: space-around;
}

/* Add spacing inside buttons for a clean look */
.controls button span {
  display: inline-block;
  margin: auto;
}

.purple-bg p {
  background-color: #dfdbff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 18px;
}

.purple-bg span {
  font-weight: 500;
}

.purple-env {
  color: #5a4ad5;
  font-weight: bold;
  font-size: 25px;
  margin-right: 8px;
}

.form-select {
  width: 100%;
  /* Adjust based on your layout */
  /* max-width: 200px; */
  /* Set maximum width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
  /* Add space for the arrow */
  appearance: none;
  /* Remove the default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
  /* Background color of the dropdown */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='gray'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  /* Position the arrow */
  background-size: 22px;
  /* Size of the arrow */
  border: 1px solid #ccc;
  /* Border style */
  border-radius: 4px;
  /* Optional: Rounded corners */
}

.custom-file-input {
  color: gray;
  /* File name text color */
}

.custom-file-input::file-selector-button {
  background-color: rgba(245, 245, 245, 1);
  /* Background color of the "Choose File" button */
  color: rgba(169, 169, 169, 1);
  /* Text color of the "Choose File" button */
  /* border: 1px solid #ced4da; */
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-input::file-selector-button:hover {
  background-color: #f8f9fa;
  /* Optional hover effect */
}

.custom-file-input::placeholder {
  color: rgba(169, 169, 169, 1);
  /* Placeholder color */
}

.custom-resubmit-btn {
  width: 160px;
}

.custom-pointer {
  cursor: pointer;
}

.mobile-nav-head {
  flex-wrap: unset !important;
}

.custom-select:invalid {
  color: gray;
  /* Gray color for the placeholder */
}

.custom-select option {
  color: black;
  /* Black color for other options */
}

.see-more-less {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .msg-span {
    margin-left: 38px;
  }
}