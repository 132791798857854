img.rounded-circle.me-3.order_logo {
    height: 56px;
    width: 55.71px;
}

.outerHeading{
    font-family: lato;
    font-size: 16px;
    line-height: 19.2px;

}
.transaction_inner {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 16.8px;
    color: #555555;
    font-weight: 400;
}
.transaction_inner_value {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 19.2px;
    color: #2C2D3A;
    font-weight: 700;
    margin-top: 10px;
}

.text_success{
    color:#00AC78;
}
.transaction_content {
    margin-left: 84px;
}

.aditya_currency{
    font-family: lato-bold;
    font-size: 24px;
    line-height: 28.8px;
    color: #00AC78;
}

.aditya_currency_time {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 16.8px;
    color: #2C2D3A;
}



@media (max-width: 576px) {
.currency {
    margin-left: 68px;
    text-align: start !important;
    margin-bottom: 10px;
}
img.rounded-circle.me-3.order_logo {
    height: 42px;
    width: 38.71px;
}
.outerHeading {
    font-size: 14px;
}
.aditya_currency { 
    font-size: 19px; 
}
}

@media (max-width: 410px) {
    .transaction_content {
        margin-left: 18px;
    }
    .currency {
        margin-left: 3px;
    }
}