.nav-item {
    position: relative;
    margin-right: 20px;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    display: block;
  }
  
  .more-item .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1c1c1c;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 180px;
  }
  
  .more-item .dropdown-menu li {
    width: 150px;
  }
  
  .dropdown-link {
    padding: 10px;
    display: block;
    color: white;
    text-decoration: none;
  }
  
  .dropdown-link:hover {
    background-color: #333;
  }
  .more-item:hover .dropdown-menu {
    display: block;
    height: 500px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}