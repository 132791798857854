/* funds card  */
/* .card-bg-white {
    background: #FFFFFF;
} */

.fund-card-sip {
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #C0C3C9;
    background: transparent;
  }
  
  .fund-card-sip  .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}
  
.fund-card-sip .fund-name-header {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16.2px;
    text-align: left;
    width: 100%;
    font-family: 'Lato';
    font-weight: 600;
    cursor: pointer;
}

.fund-card-sip .hover_box:hover  .fund-name-header ,.fund-card-sip .hover_box:active .fund-name-header {
    color: #5A4AD5;
}

.fund-card-sip .custom-button {
    border-radius: 20px;
    padding: 9px 14px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    letter-spacing: 0px;
    border: 0px;
    background-color: transparent;
    color: #FFFFFF;
}

.fund-card-sip .custom-status {
    border-radius: 20px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    letter-spacing: 0px;
    border: 0px;
    background-color: transparent;
    color: #FFFFFF;
}
.yellow-color{
    background-color: #FFFAB8 !important;
    color: #E0B130 !important;
}
.danger-color{
    background-color: #FFE4E4 !important;
    color: #EC0404 !important;
}

.fund-card-sip  .investment-details {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 0.4px;
}

.fund-card-sip  .investment-details span {
    font-size: 17px;
    /* font-weight: 700; */
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
    margin-top: 5px;
    font-family: 'Lato-bold';
}
.fund-card-sip .accordion-collapse {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.fund-card-sip .accordion-button::after{
    background-image: unset;
}
.fund-card-sip .accordion-button:not(.collapsed)::after {
    background-image: unset !important;
    transform: var(--bs-accordion-btn-icon-transform);
}

.fund-card-sip .accordion-item:first-of-type>.accordion-header .accordion-button[aria-expanded="false"] {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.fund-card-sip .accordion-item:first-of-type>.accordion-header .accordion-button[aria-expanded="true"] {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.fund-card-sip .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed  {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.sip-accordian.accordion {
    --bs-accordion-bg: unset;
    --bs-accordion-border-color: unset;

}
/* test  */
.fund-card-sip .fund-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #5A4AD5 0%, #3583FF 100%);
    color: #fff;
    border-radius: 12px;
    padding: 20px 20px;
}
.sip-accordian-table tbody .table_row {
    border-bottom: 1px solid #ffff;
    border-width: 10px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

.sip-accordian-table tbody .table_data {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #555555;
    text-wrap: nowrap;
}
.sip-accordian-table tbody .table_data .data_span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
    margin-top: 6px;
}
  .table th,
  .table td {
    text-align: center;
    vertical-align: middle;
  }

  

@media (min-width:576px) {
    .fund-card-sip .fund-name-header {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 19.2px;
        text-align: left;
        width: 100%;
        font-family: 'Lato';
        font-weight: 600;
        cursor: pointer;
    }
}
@media (min-width:700px) {
    .fund-card-sip .grid_content {
        display: grid;
        grid-template-columns: auto auto;
        gap: 16px;
    }
}
@media (min-width:768px) {
    
    .custom_dropdown{
        position: unset;
        top: 0;
        padding: 0px;
    }
    .fund-card-sip .grid_content {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0px;
    }

}

@media (min-width:992px) {
   
}

@media (min-width:1200px) {
    .fund-card-sip .grid_content {
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .fund-card-sip  .investment-details {
        font-size: 15px;
    }
    .fund-card-sip  .investment-details span {
        font-size: 18px;
    }
}
@media (min-width:1300px) {
 
}

@media (max-width:500px) {
    .sip-accordian-table tbody .table_data{
display: block;
}
.sip-accordian-table tbody .table_row {
    border-bottom: 1px solid #e0d7d7;

}
.sip-accordian-table .accordion-button::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f106';
    position: absolute;
    right: 1rem;
    top: 14%  !important;
    transform: translateY(-50%);
}

.fund-card-sip .fund-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #5A4AD5 0%, #3583FF 100%);
    color: #fff;
    border-radius: 12px;
    padding: 15px 15px;
    flex-wrap: wrap;
    gap: 10px;
}
.sip-accordian-table tbody .table_data .data_span {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.6px;
    text-align: left;
    color: var(--dark-gray);
    margin-top: 6px;
}
}