* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Lato-l';
    src: url('../fonts/Lato/Lato-Light.ttf') format('truetype');

}

@font-face {
    font-family: 'Lato-bold';
    src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');

}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Montserrat-m';
    src: url('../fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');

}
@font-face {
    font-family: 'Montserrat-eb';
    src: url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'Archivo_Black';
    src: url('../fonts/Archivo_Black/ArchivoBlack-Regular.ttf') format('truetype');

}


:root {
    --primary-color: #0E0841;
    --primary-second: #5A4AD5;
    --primary-third: #0B24C2;
    --secondary-color: #6c757d;
    --background-color: #f8f9fa;
    --text-color: #212529;
    --light-blue-color: #3583FF;
    --dark-gray: #2C2D3A;
    --light-second-gray: #555555;
    --light-gray: #E8ECF1;
    --pista-green: #34D3B7;
    --dark-green: #00AC78;
    --light-green: #BBFFEA;
    --light-purple: #CFC9FF;
    --light-white: #F5F5F5;
    --linear-light-blue: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    --linear-light-border: linear-gradient(261.58deg, #AA89FF -7.18%, #387FF6 107.3%);
    --placeholder-color:#A9A9A9;
    --text-light-gray:#829AB9;
}



body {
    font-family: "Lato", sans-serif;
    background: var(--primary-color);
}

.background-purple {
    background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
}

a {
    text-decoration: none;
}
.btn:hover {
    color: #ffff;
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #ffff;
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
}
.get_start_btn {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    padding: 15px 38px;
    border-radius: 24px;
    transition: 2s all ease;
    
}
.get_start_btn_white{
    color: var(--dark-gray);
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.get_start_btn_white:hover,.get_start_btn_white:active{
    background-color: transparent;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.get_start_btn_blue{
    color: #FFFFFF;
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 1px solid linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
}
.get_start_btn_blue:hover,.get_start_btn_blue:active{
    color: #FFFFFF;
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 1px solid linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
}
.section-title {
    font-family: Lato;
    font-size: 35px;
    font-weight: 700;
    line-height: 43.6px;
    text-align: center;
}
.section-title {
    font-family: Lato;
    font-size: 30px;
    font-weight: 700;
    line-height: 39.6px;
    text-align: center;
}

@media (min-width:576px) {
    .section-title {
        font-size: 35px;
        line-height: 43.6px;
    }
    
}

@media (min-width:768px) {
    .section-title {
        font-size: 40px;
        line-height: 50.6px;
    }
    
}
@media (min-width:1200px) {
    .section-title {
        font-size: 40px;
        line-height: 51.6px;
    }
    
}
@media (min-width:1400px) {
    .section-title {
        font-size: 48px;
        line-height: 57.6px;
    }
    
}
/* header section  */
#header_section {
    background-color: var(--primary-color);
}

/* Custom styles */
.navbar {
    background-color: var(--primary-color);
    /* Dark blue background */
}

.navbar-brand img {
    height: 34px;
    /* Adjust logo size */
}

.navbar-toggler-icon {
    filter: invert(1);
    outline: none;
}

.navbar-brand {
    color: #FFFFFF;
    font-family: Montserrat-eb;
    font-size: 21.31px;
    font-weight: 700;
    line-height: 25.98px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.navbar-brand:hover {
    color: #FFFFFF;
}

.navbar-brand:focus, .navbar-brand:hover {
    color: #FFFFFF;
}
.navbar-nav .nav-link {
    color: #E5E2FF;
    font-family: Lato-bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-inline: 23px !important;
}

.nav-link:hover {
    color: #FFFFFF !important;
    /* Highlight color on hover */
}

.btn-login {
    background: linear-gradient(250.6deg, #3583FF 0.04%, #204F99 98.67%);
    color: #FFFFFF;
    border-radius: 24px;
    padding: 11px 25px;
    font-family: Lato;
    border: unset;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0.1px;
}

@media (max-width:767px) {
    .navbar-brand img {
        height: 30px;
    }
}
@media (min-width:576px) {
    .btn-login {
        padding: 14px 30px;
    }
}

/* header section  */

/* hero section  */
.home_hero_section{
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}
#hero_section {
    height: 650px;
}
.hero_section {
    background-color: var(--primary-color);
    position: relative;
    background-image: url('../images/heroimg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}

#hero_section:before {
    content: "";
    background: #1B5EC866;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.hero_inner_section.text-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    display: table;
    height: 100%;
    width: 100%;
}

.hero_inner_section  div {
    display: table-cell;
    vertical-align: middle;
}

.home_hero_section .background-video{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.hero_inner_section h1, .hero_inner_section span {
    font-family: Lato;
    font-size: 65px;
    line-height: 86.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    font-weight: 800;
}

@media (min-width:992px){
    .hero_inner_section h1, .hero_inner_section span {
        font-size: 70px;
        width: 700px;
        margin: 0 auto;
    }
}
@media (min-width:1024px){
    .hero_inner_section h1, .hero_inner_section span {
        font-size: 70px;
        width: 700px;
        margin: 0 auto;
    }
}
@media (min-width:1400px){
    .hero_inner_section h1, .hero_inner_section span {
        font-family: Lato;
        font-size: 72px;
        line-height: 86.4px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        font-weight: 800;
        width: 800px;
        margin: 0 auto;
    }
}
.hero_inner_section h1 {
    font-weight: 800;
}

.hero_inner_section span {
    font-weight: 400;
}

@media (max-width:991px) {
    #hero_section {
        height: 500px;
    }

    .hero_inner_section h1,
    .hero_inner_section span {
        font-size: 50px;
        line-height: 65.4px;
        width: 560px;
        margin: 0 auto;
    }
}

@media (max-width:767px) {
    #hero_section {
        height: 500px;
    }

    .hero_inner_section h1,
    .hero_inner_section span {
        font-size: 50px;
        line-height: 65.4px;
        width: 500px;
        margin: 0 auto;
    }
}

@media (max-width:575px) {
    #hero_section {
        height: 330px;
    }

    .hero_inner_section h1,
    .hero_inner_section span {
        font-size: 37px;
        line-height: 49.4px;
        width: 100%;
        padding: 10px;
    }
}

@media (max-width:375px) {
    #hero_section {
        height: 300px;
    }

    .hero_inner_section h1,
    .hero_inner_section span {
        font-size: 33px;
        line-height: 40.4px;
    }
}

/* end hero section  */
/* footer section  */
#footer_section {
    background-color: var(--primary-color);
}

.footer_section {
    padding: 60px 0px;
}

.footer_inner_content {
    padding: 0px 35px;
}

.top-footer {
    display: grid;
    grid-template-columns: auto;
    justify-content: space-between;
    gap: 24px;
}

.divider_footer {
    border-bottom: 1.5px solid #362F70;
    display: block;
}

.top-footer .navbar-brand {
    margin-bottom: 30px;
}

.top_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.top_content_link img {
    width: 20px;
    height: 20px;
}

.top_content li {
    list-style-type: none;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 22.2px;
    text-align: left;
    color: #FFFFFF;
}

.top_content li a {
    color: #FFFFFF;
}

.top-footer .footer-heading {
    display: block;
    margin-bottom: 30px;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.social-icon li {
    list-style-type: none;
}

.social-icon li .icon-round {
    list-style-type: none;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in;
}

.social-icon li .icon-round:hover,
.social-icon li .icon-round:active {
    background-color: var(--light-blue-color);
    border: 1px solid var(--light-blue-color);
}

.social-icon li .icon-round:hover img,
.social-icon li:active .icon-round img {
    filter: invert(1);
}

.copy-right-section {
    padding: 30px 0px;
}

.copy-right-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #FFFFFF;
}
.bottom-heading {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffff;
    margin-bottom: 20px;
}
.bottom-footer .bottom-text,.bottom-footer .bottom-text a {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    flex-wrap:wrap;
}

.bottom-footer .bottom-text .span_highlight {
    font-family: Montserrat;
    font-weight: 600;
}
.bottom-footer .bottom-text span {
  padding: 0px 7px;
}

.download_button img{
    height: 40px;
}
@media (min-width:576px){
    .top-footer {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
    }
    .footer_inner_content {
        padding: 0px 10px;
    }
    .top-footer .navbar-brand {
        margin-bottom: 30px;
    }
    .bottom-footer .bottom-text,.bottom-footer .bottom-text a {
        font-size: 14px;
    }
    .bottom-heading {
        font-size: 24px;
        margin-bottom: 30px;
    }
    .top_content li {
        list-style-type: none;
        font-family: Lato;
        font-size: 17px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: #FFFFFF;
    }
}
@media (min-width:768px){
    .top-footer {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
    }
    .footer_inner_content {
        padding: 0px 20px;
    }
    .footer_section {
        padding: 95px 0px;
    }
    .top-footer .navbar-brand {
        margin-bottom: 30px;
    }
    .top-footer .footer-heading {
        margin-bottom: 40px;
    }
    .download_button img{
        height: 58px;
    }
}
@media (min-width:1024px){
    .top-footer {
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: space-between;
    }
    .footer_inner_content {
        padding: 0px 35px;
    }
    .top-footer .navbar-brand {
        margin-bottom: 30px;
    }
}
/* end footer section  */



.slider-label,
.result-text {
    color: var(--dark-gray);
    margin-bottom: 8px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}

.slider-value {
    background-color: #E8EAFD;
    color: var(--primary-second);
    padding: 5px 12px;
    border-radius: 6px;
    min-width: 60px;
    width: 100px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: right;
}

.slider {
    width: 100%;
    height: 4px;
    background-color: #3C3C432E;
    border-radius: 8px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    box-shadow: 0px 6px 13px 0px #0000001F;
    box-shadow: 0px 0.5px 4px 0px #0000001F;

}

@media (min-width:576px) {

    .slider-value {
        width: 140px;
        font-size: 17px;
    }
    .slider-label,
    .result-text {
        font-size: 17px;
    }

}
@media (min-width:768px) {
    .slider::-webkit-slider-thumb {
        height: 28px;
        width: 28px;

    }
}
.goal_planner_section {
    border-radius: 12px;
    box-shadow: 0px 4px 20px 0px #E1DEDC;
    border: none;
}

.goal_planner_content h5 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 1px;
}
/* funds card  */
