.page_background {
  /* background-color: #f8f9fa; */
  min-height: 100vh;
  padding: 20px;
}

.page-inner-content1 {
  max-width: 1200px;
  margin: 0 auto;
}

.page_inner_content_box {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.main-heading-title {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.back_icon {
  text-decoration: none;
  color: #666;
}

.customer-info {
  padding: 20px;
  margin-bottom: 24px;
}

/* .report-heading-title {
  font-weight: 600;
  font-size: 15px;
  color: #181c32;
} */

.report-heading-title {
  font-size: 16px;
  font-weight: 600;
  color: #181c32;
}

.report-sub-title {
  font-weight: normal !important;
  color: #3f4254;
}

.report-sub-title-div {
  color: #3f4254;
  font-size: 15px;
  font-weight: 600;
}

/* Table Css Start here  */

.report-table {
  width: 100%;
  overflow-x: auto;
}

/* .table-main-heading {
  background-color: red !important;
} */

.table-main-heading th {
  /* background-color: #e8ecf1; */
  background-color: #594ad5;
  text-align: start !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #ffc387 !important;
}

.table-header rt th {
  /* background-color: #fff8f1; */
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}

.sub-total {
  /* background-color: #f8f9fa; */
  font-weight: 600;
  text-align: right;
  font-size: 12px;
  /* Font size for subtotal row */
}

.sub-total-td-color {
  color: #8950fc !important;
  font-weight: 600;
}

.sub-total-td-color2 {
  color: #594ad5 !important;
  font-weight: 600;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  text-align: center;
  font-size: 12px;
  color: #3f4254;
}

.table-responsive {
  margin: 1rem 0;
}

.no-bottom-border {
  border-bottom: none !important;
}

.no-top-border {
  border-top: 1px none !important;
}

.custom-val-th {
  position: relative;
  top: 17px;
  left: 9px;
}

.grand-total-row {
  display: none !important; /* Hide by default */
}

.grand-total-row.last-row {
  display: table-row !important; /* Show only when it's the last row */
}

/* tr.grand-total {
  display: none !important;
}


tr.grand-total:last-of-type {
  display: table-row !important;
} */

/* Hide all grand-total rows */
.grand-total {
  display: none;
}

/* Show only the last grand-total row */
.grand-total:last-child {
  display: table-row;
}
