.pagination {
    --bs-pagination-padding-x: 0.55rem;
    --bs-pagination-padding-y: 0.375rem;
    /* --bs-pagination-font-size: 1rem; */
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: unset;
    --bs-pagination-border-color: var(--bs-border-color);
    /* --bs-pagination-border-radius: var(--bs-border-radius); */
    /* --bs-pagination-hover-color: var(--bs-link-hover-color); */
    /* --bs-pagination-hover-bg: var(--bs-tertiary-bg); */
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: unset;
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pagination .page-link {
    color: var(--dark-gray);
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    background-color: transparent;

}

.pagination .page-link:hover {
    color: var(--primary-second);
    /* Maintain blue on hover */
    text-decoration: none;
}

.pagination .page-item.active .page-link {
    background-color: transparent;
    border: none;
    color: var(--primary-second);
    font-weight: bold;
    /* text-decoration: underline; */
}
.pagination  .page-item.active {
    border-bottom: 3px solid var(--primary-second);
}

.pagination .page-item.disabled .page-link {
    color: #6c757d;
    /* Bootstrap default gray for disabled items */
}
.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    /* background-color: var(--bs-pagination-focus-bg); */
    outline: 0;
    box-shadow: unset;
}