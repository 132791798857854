.cart-custom-popup {
  padding: 0px !important;
}

.custom-popup-content {
  margin: 0px !important;
}

.goals-custom-popup {
  padding: 0px !important;
  max-width: 500px !important;
}

.goals-custom-header {
  padding: 20px !important;
}

.my-cart-custom-body {
  padding: 8px !important;
}

.my-cart-custom-no-border {
  border-bottom: none !important;
  padding: 0px !important;
}
.my-cart-custom-no-border2 {
  border-bottom: none !important;
}

.my-cart-scrollbar {
  scrollbar-color: #a7abaf #ffffff;
  scrollbar-width: thin;
}

.custom-model-footer {
  padding: 0 !important;
  padding-bottom: 20px !important;
}
