.holdings-container {
  display: flex;
  flex-direction: column;
}


.sidebar-button {
  padding: 10px 30px;
  border: none;
  margin-bottom: 0px;
  border-radius: 20px;
  cursor: pointer;
  background: #CBD2DA;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555555;
}

.sidebar-button.active,
.sidebar-button:hover {
  background: var(--linear-light-blue);
  color: white;

}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--primary-third);
}
.dropdown-item:active .dropdown_img,.dropdown-item.active .dropdown_img  {
  filter: invert(1) brightness(11.5);
}
.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-third);
}
.dropdown-item:hover .dropdown_img {
  filter: invert(1) brightness(11.5);
}

@media (min-width:768px) {
 

  }

  @media (min-width:992px) {
    .holdings-container {
      display: grid;
      grid-template-columns: 10% 88%;
    }
    
    .sidebar-button {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
