#wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-height: 100vh;
    width: 120px;
    transition: all 0.3s;
    margin-left: 0px;
    background-color: var(--primary-color);

}

/* #sidebar.active {
    margin-left: 0;
} */

#page-content-wrapper {
    width: 100%;
    /* margin-left: 120px; */
}

.profile_navbar .navbar {
    padding: 10px;
}

nav.profile_navbar.navbar {
    height: auto;
    /* margin-left: 120px; */
    position: relative
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

/* #menu-toggle {
    position: absolute;
    top: 24px;
} */
.sidebar-header {
    position: sticky;
    top: 0;
    padding: 25px 20px;
    font-size: 1.5rem;
    color: white;
    text-transform: uppercase;
    background-color: inherit;
    z-index: 1010;
}

ul.components {
    padding: 20px 15px;
}

.main_menu li {
    /* padding: 10px; */
    font-size: 1.1em;
    display: block;
}

.sidebar-header img {
    width: 84px;
    height: 39px;
}

/* sidebar items  */

.main_menu li a {
    padding: 16px 20px;
    display: flex;
    border-radius: 12px;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.main_menu li a .pcoded-mtext {
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: var(--text-light-gray);
}

.main_menu li a img {
    width: 28px;
    height: 28px;
}

.main_menu li a:hover {
    color: #fff;
    background: var(--light-blue-color);
    opacity: unset;
}

.main_menu li a:active,
.main_menu li a.active {
    background: var(--light-blue-color);
    opacity: unset;
}

.main_menu li a:active .pcoded-mtext,
.main_menu li a.active .pcoded-mtext {
    color: #fff;
}

.main_menu li a:active img,
.main_menu li a.active img {
    filter: invert(1) brightness(4.5);
}


.main_menu li a:hover .pcoded-mtext {
    color: #fff;
}

.main_menu li a:active,
.main_menu li a.active {
    background: var(--light-blue-color);
    opacity: unset;
}

.main_menu li a:active .pcoded-mtext,
.main_menu li a.active .pcoded-mtext {
    color: #fff;
}

.main_menu li a:active img,
.main_menu li a.active img {
    filter: invert(1) brightness(4.5);
}

.main_menu li a .active .pcoded-mtext {
    color: #fff;
}

.main_menu li a:hover img {
    filter: invert(1) brightness(4.5);
}

.main_menu li.active>a,
a[aria-expanded="true"] {
    color: #fff !important;
    /* background: var(--light-blue-color); */
    opacity: unset;
}

.avtar img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
}



.header-images {
    width: 24px;
    height: 24px;
}

span.badge_counter {
    width: 25px;
    display: flex;
    height: 14px;
    background: #FFA144;
    justify-content: center;
    align-items: center;
    font-family: 'Lato';
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.0168em;
    text-align: center;
    border-radius: 50px;
    position: absolute;
    top: 11px;
    right: 9px;
    color: #ffff;
}

.managed_navbar.navbar-nav .nav-link {
    padding: 10px !important;
}

.form-switch .switch_mode {
    width: 39px;
    height: 20px;
}

/* General sidebar styles */
#sidebar {
    position: fixed;
    top: 0px;
    left: 0;
    height: 100vh;
    background: var(--primary-color);
    transition: all 1s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* WebKit Browsers */
#sidebar::-webkit-scrollbar {
    width: 0px;
    /* Thin scrollbar width */
}

#sidebar::-webkit-scrollbar-track {
    background: #2c2f33;
    /* Track background */
}

#sidebar::-webkit-scrollbar-thumb {
    background-color: var(--light-blue-color);
    /* Scrollbar color */
    border-radius: 10px;
    border: 2px solid #2c2f33;
    /* Padding around the scrollbar handle */
}

#sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #004aad;
    /* Darker color on hover */
}

/* Sidebar is visible (active state) */
#sidebar.active {
    transform: translateX(0);
}

/* Sidebar is hidden (inactive state) */
#sidebar:not(.active) {
    transform: translateX(-100%);
}

#close-sidebar {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1100;
    /* Ensure it stays on top */
}

#close-sidebar i {
    color: #fff;
    /* Icon color */
}

#sidebar .sidebar-header {
    position: relative;
    /* Ensure the close button stays in place */
}

#dashboard_footer {
    /* height: 40px; */
    background: #170F58;
    margin: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 12px;
}

.dashboard_footer .nav_link {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #FFFF;
}

.calendericon {
    right: 5px !important;
    top: 3px !important;
}

@media (min-width: 576px) {


    #menu-toggle {
        position: unset;
    }

    .managed_navbar.navbar-nav .nav-link {
        padding: 14px !important;
    }

    span.badge_counter {
        top: 11px;
        right: 0px;
    }

}

@media (max-width: 375px) {}

@media (min-width: 768px) {
    .main_menu li a img {
        width: 32px;
        height: 32px;
    }


}

/* Adjust content wrapper when sidebar is active on larger screens */
@media (min-width: 992px) {
    .padding-left {
        padding-left: 120px;
    }

    #close-sidebar {
        display: none;
    }

    #sidebar {
        transform: translateX(0);
    }

    #sidebar:not(.active) {
        transform: translateX(0);
    }


}

/* Ensure content is full width when sidebar is hidden on smaller screens */
@media (max-width: 991px) {
    #wrapper {
        padding-left: 0;
        /* No space reserved for sidebar */
    }

    #close-sidebar {
        display: block;
    }
}

/* end sidebar css */


/* form css  */
.page_background {
    padding: 20px;
}

.page_background .page-inner-content {
    border-radius: 12px;
    overflow: hidden;
}

.page_background .page_inner_content_box {
    background-color: var(--light-gray);
    padding: 25px;
    /* height: 100vh;
    overflow-y: auto;
    scrollbar-width: none; */
}

.page_background .page_content_overflow {
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
}

/* progress bar  */
.progress-container {
    padding: 10px 20px;
    background-color: #F7F8FB;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.page-title {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    color: var(--dark-gray);

}

.step-info {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--light-blue-color);

}

.progress-bar {
    position: relative;
    overflow: unset;
    border: 1px solid #C0C3C9;
}

.progress-filled {
    /* height: 100%; */
    /* border-radius: 0px; */
    border: 4px solid #3583FF;
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 0;
}

/* progress bar  */

.form_card {
    background: #ffff;
    border-radius: 12px;
    padding: 20px 20px;
}


.form-label {
    margin-bottom: 12px;
}

.form-label,
.form-check-label {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
}

.form-control,
.form-select {
    font-size: 16px;
    background-color: #ffff;
    border: 1px solid #C0C3C9;
    border-radius: 4px;
    padding: 0.575rem .85rem;
    color: var(--dark-gray);
    font-family: Lato;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}

.form-control::placeholder {
    color: #A9A9A9;
}

/* .form-select{
    color: #A9A9A9;
  } */
/* .form-select option:first-child {
    color: #A9A9A9 !important;
} */

option:not(:first-of-type) {
    color: var(--dark-gray)
}

.form-check-input:checked {
    background-color: #3583FF;
    border-color: #3583FF;
}

.form-check-input:checked {
    background-color: #3583FF;
    border-color: #3583FF;
    box-shadow: none;
}

.form-check-input[type="radio"] {
    border-radius: 50%;
    color: #555555;
    border: 2px solid #555555;
    width: 20px;
    height: 20px;
}

input[type="date"] {
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* background-image: url('../images/sidebar_icon/calendar_month.svg'); */
    background-repeat: no-repeat;
    background-position: right 10px center;
    /* padding-right: 30px; */

}

.custom_date_input img {
    position: absolute;
    top: 2px;
    right: 5px;
    display: flex;
    background: #ffff;
    align-items: center;
    justify-content: center;
    padding: 9px;
}

textarea.form-control {
    /* width: 390px; */
    height: 80px;
    resize: none;
}

/*/ end  form css  */
.info-box {
    background-color: #FFFF;
    border-radius: 8px;
}

.info-box h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
}

.info-box p {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #555555;

}

.info-box .highlight_text {
    font-style: italic;

}

.card_footer {
    border-top: 1px solid #C0C3C9;
}

.step_form {
    border-radius: 12px;
    overflow: hidden;
}

.form_button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: #FFFF;
    border-radius: 28px;
    color: var(--primary-second);
    height: 40px;
    border: 2px solid var(--primary-second);
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.next_btn {
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 0px;
    color: #fff;
}

.main-heading-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 1px;
}

.back_icon {
    font-size: 20px;
    color: var(--dark-gray);
}

.custom_date_input {
    width: 100%;
}

.form-check {
    display: flex;
    align-items: center;
    gap: 4px;
}

/* input[type="date"]:before {
    content: attr(placeholder);
    font-size: 14px;
} */

@media (min-width: 320px) {}

@media (min-width: 576px) {
    .main-heading-title {
        font-size: 24px;
    }

    .back_icon {
        font-size: 24px;
        color: var(--dark-gray);
    }

    .page_background {
        padding: 20px;
    }

    #dashboard_footer {
        margin: 0px 20px;
    }

    #menu-toggle {
        position: unset;
    }

    .form_card {
        background: #ffff;
        border-radius: 12px;
        padding: 20px 20px;
    }

    .form-label,
    .form-check-label {
        margin-bottom: 0px;
        font-size: 17px;
    }

    .form-control,
    .form-select {
        font-size: 17px;
    }

    .form_button {
        height: 45px;
        width: 110px;
    }

    .page_background .page_inner_content_box {
        padding: 28px;
    }
}


@media (min-width: 768px) {
    .page_background {
        padding: 30px;
    }

    #dashboard_footer {
        margin: 0px 30px;
    }

    .form_card {
        background: #ffff;
        border-radius: 12px;
        padding: 25px 40px;
    }

    .page_background .page_inner_content_box {
        padding: 35px;
    }

    .form_button {
        font-size: 16px;
        height: 55px;
        width: 125px;
    }

    nav.profile_navbar.navbar {
        height: 105px;
        /* margin-left: 120px; */
    }

}

/* Adjust content wrapper when sidebar is active on larger screens */
@media (min-width: 992px) {

    .page_background {
        padding: 24px 40px;
    }

    #dashboard_footer {
        margin: 0px 40px;
    }

    .form_card {
        background: #ffff;
        border-radius: 12px;
        padding: 41px 60px;
    }

    #sidebar {
        position: absolute;
        height: auto;
    }


}


/* Ensure content is full width when sidebar is hidden on smaller screens */
@media (min-width: 1200px) {
    .custom_date_input {
        width: 55%;
    }
}

@media (min-width: 1400px) {
    .custom_date_input {
        width: 50%;
    }
}

.custom-date-wrapper2 {
    position: relative;
    /* width: 100%; */
}

.custom-date-wrapper2 input {
    /* width: 100%; */
    /* padding: 10px; */
    /* font-size: 16px; */
    background: transparent;
    position: relative;
    /* z-index: 2; */
    /* border: 1px solid #ccc; */
}

.custom-date-wrapper2 .custom-placeholder2 {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    color: #A9A9A9;
    font-size: 16px;
    pointer-events: none;
    transition: 0.2s ease all;
    z-index: 999;
    opacity: 1;
    background: #ffff;
}

.custom-date-wrapper2 input:focus+.custom-placeholder2,
.custom-date-wrapper2 input.has-value+.custom-placeholder2 {
    opacity: 0;
    visibility: hidden;
}

input[type="checkbox"],
input[type="radio"] {
    margin-bottom: 3px;
}


.custom-date-wrapper .passwordicon {
    width: 40px;
}