/* funds card  */
.card-bg-white {
    background: #FFFFFF;
}

.wishlist_card {
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #C0C3C9
}

.wishlist_card .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}

.wishlist_card .fund-name-header {
    color: var(--dark-gray);
    font-size: 16px;
    line-height: 19.2px;
    text-align: left;
    width: 100%;
    font-family: 'Lato';
    font-weight: 600;
    cursor: pointer;
}

.order_box:hover .outerHeading {
    color: #5A4AD5;
}

.fund-details-right .bookmark-icon {
    filter: none;
}

.bookmarked .bookmark-icon {
    filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(3000%) hue-rotate(210deg) brightness(95%) contrast(100%);
}

.wishlist_card .custom-badge {
    border-radius: 20px;
    padding: 5px 13px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    letter-spacing: 0px;
}

.orange-badge {
    background-color: #FADBBE;
    color: #F5820A;
}

.green-badge {
    background-color: var(--light-green);
    color: var(--dark-green);
}

.purple-badge {
    background-color: var(--light-purple);
    color: var(--primary-second);
}

.wishlist_card .investment-details {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 0.4px;
}

.wishlist_card .investment-details span {
    font-size: 17px;
    /* font-weight: 700; */
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
    margin-top: 5px;
    font-family: 'Lato-bold';
}

.wishlist_card .card-body {
    display: flex;
    row-gap: 13px;
    flex-direction: column;
}

.btn_delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #FFDDDD;
    border-radius: 6px;
}

.btn_delete img {
    width: 20px;
    height: 22px;
}
.custom_dropdown {
    position: absolute;
    top: -14px;
    right: 0;
    padding: 20px 14px;
}

.custom_dropdown a[aria-expanded="true"] {
    color: #fff;
    background: transparent;
    opacity: unset;
}

.custom_dropdown .dropdown_img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

@media (min-width:576px) {
    .wishlist_card .card-body {
        display: flex;
       flex-direction: row;
    }
}


@media (min-width:768px) {
    .wishlist_card .card-body {
        display: flex;
       flex-direction: row;
    }
    
}
@media (min-width:992px) {
    .wishlist_card .card-body {
        display: grid;
        grid-template-columns: 11% 89%;
    }
    
    .custom_dropdown {
        position: unset;
        top: 0;
        padding: 0px;
    }
}

@media (min-width:1200px) {
    .wishlist_card .card-body {
        display: grid;
        grid-template-columns: 9% 91%;
    }
}

@media (min-width:1300px) {
    .wishlist_card .card-body {
        display: grid;
        grid-template-columns: 8% 92%;
    }
}

@media (min-width:1400px) {
    .wishlist_card .card-body {
        display: grid;
        grid-template-columns: 7% 93%;
    }
}