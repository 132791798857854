/* funds card  */
.card-bg-white {
    background: #FFFFFF;
}

.fund-card-five {
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #C0C3C9
  }
  
  .fund-card-five  .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}
  
.fund-card-five .fund-name-header {
    color: var(--dark-gray);
    font-size: 16px;
    line-height: 19.2px;
    text-align: left;
    width: 100%;
    font-family: 'Lato';
    font-weight: 600;
    cursor: pointer;
}

.fund-card-five .hover_box:hover  .fund-name-header ,.fund-card-five .hover_box:active .fund-name-header {
    color: #5A4AD5;
}

.fund-details-right .bookmark-icon {
    filter: none;
}
.bookmarked .bookmark-icon {
    filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(3000%) hue-rotate(210deg) brightness(95%) contrast(100%);
  }

  .fund-card-five .custom-badge {
    border-radius: 20px;
    padding: 5px 13px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    letter-spacing: 0px;
}
.orange-badge{
    background-color: #FADBBE;
    color: #F5820A;
}
.green-badge{
    background-color: var(--light-green);
    color: var(--dark-green);
}
.purple-badge{
    background-color: var(--light-purple);
    color: var(--primary-second);
}

.fund-card-five  .investment-details {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 0.4px;
}

.fund-card-five  .investment-details span {
    font-size: 17px;
    /* font-weight: 700; */
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
    margin-top: 5px;
    font-family: 'Lato-bold';
}

.fund-card-five .card-body {
    display: flex;
    row-gap: 13px;
    flex-direction: column;
}

a.view_all_btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: right;
    color: var(--light-blue-color);
}

.custom_dropdown {
    position: absolute;
    top: -14px;
    right: 0;
    padding: 14px;
}

.custom_dropdown a[aria-expanded="true"] {
    color: #fff;
    background: transparent;
    opacity: unset;
}
.custom_dropdown .dropdown_img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}
.fund-card-five .grid_content {
    display: grid;
    grid-template-columns: auto;
    gap: 16px;
}

@media (min-width:700px) {
    .fund-card-five .grid_content {
        display: grid;
        grid-template-columns: auto auto;
        gap: 16px;
    }
}
@media (min-width:768px) {
    
    .custom_dropdown{
        position: unset;
        top: 0;
        padding: 0px;
    }
    .fund-card-five .grid_content {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0px;
    }

}

@media (min-width:992px) {
   
}

@media (min-width:1200px) {
    .fund-card-five .grid_content {
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .fund-card-five  .investment-details {
        font-size: 15px;
    }
    .fund-card-five  .investment-details span {
        font-size: 18px;
    }
}
@media (min-width:1300px) {
 
}

@media (min-width:1400px) {
   
}