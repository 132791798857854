#auth_section .nav-tabs {
    border-bottom: unset !important;
    justify-content: center;
}

#auth_section .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--light-second-gray);
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

#auth_section .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: none;
    padding: 9px 3px;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: center;
    letter-spacing: 0.7px;
}

#auth_section .nav-link:hover {
    color: var(--primary-second) !important;
}

#auth_section .nav-tabs .nav-link.active {
    color: var(--primary-second) !important;
    /* font-weight: bold; */
    border-color: transparent;
    border-bottom: 3px solid var(--primary-second);
    background-color: transparent;
}

#auth_section {
    background-color: var(--light-gray);
    /* height: 100vh; */
    display: table;
    width: 100%;
}

#auth_section .login-container {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.auth_left_content {
    background-color: var(--primary-color);
    padding: 65px 40px;
    text-align: center;
    border-top-right-radius: 80px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
}
.auth_left_content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.auth_left_content h3, .geeting_text {
    font-family: Lato;
    font-size: 22px;
    font-weight: 700;
    line-height: 34.2px;
    color: #FFFFFF;
}


.auth_left_content .login-image {
    max-width: 595px;
    margin: 0 auto;
    width: 100%;
}
.right-side-container .tab-content{
    background: #ffff;
    padding: 25px;
    border-radius: 12px;
}
.right-side-container {
    padding: 35px 10px;
    margin: 0px 10px;
    /* height: 100vh; */
}

#auth_section .form-title {
    font-family: Lato;
    font-size: 25px;
    font-weight: 700;
    line-height: 40.2px;
    color: var(--dark-gray);
    text-align: center;
    margin: 20px 0px;
}

#auth_section .form-label {
    margin-bottom: 15px;
    padding-left: 10px;
}
#auth_section .form-label.confirm_box {
    font-size: 14px;
    font-weight: 500;
}
label.form-label.confirm_box a {
    color: #3583FF;
    font-weight: 700;
}

#auth_section .form-label,
#auth_section .form-check-label {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    color: var(--dark-gray);
}

.form-check-input[type="radio"] {
    border-radius: 50%;
    color: #555555;
    border: 2px solid #555555;
    width: 20px;
    height: 20px;
}

#auth_section .form-control,
#auth_section .form-select {
    padding: 15px 19px !important;
    border-radius: 10px;
    border: 1px solid #D0D0D0;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: var(--dark-gray);
    background-color: #FFFFFF;
}
#auth_section .form-group{
margin-bottom: 20px;
}

.passwordicon {
    position: absolute;
    right: 19px;
    top: 0;
    bottom: 0;
    width: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.passwordicon .color{
    vertical-align: -webkit-baseline-middle;
    background-color: #fff;
    padding: 5px 5px;
    color: #D0D0D0;
    width: 20px;
    height: 18px;
}
.calendericon img {
    vertical-align: -webkit-baseline-middle;
    background-color: #fff;
    padding: 2px 2px;
    color: #D0D0D0;
    width: 26px;
    height: 30px;
}

#auth_section .form-control::placeholder {
    color: var(--placeholder-color);
}

#auth_section .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: unset;
}
#auth_section .form-check-input[type="checkbox"] {
    color: var(--light-blue-color);
    width: 20px;
    height: 20px;
    border: 1px solid var(--dark-gray);
    margin-right: 10px;
    border-radius: 4px;
    vertical-align: middle;
}
#auth_section .form-check-input[type="checkbox"]:checked{
    border: 1px solid transparent;
}
#auth_section .form_button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: #FFFF;
    border-radius: 35.5px;
    color: var(--primary-second);
    height: 40px;
    border: 2px solid var(--primary-second);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.6px;
}

#auth_section .next_btn {
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 0px ;
    color: #fff;
}

#auth_section .form-control::placeholder {
    color: #A9A9A9;
}

#auth_section .forget_password{
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: #3583FF;
letter-spacing: 1px;
}


#auth_section .otp-page {
    display: flex;
    height: 100vh;
}

#auth_section .back-link {
    color: #3583FF;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 16.8px;
    margin-bottom: 20px;
    text-decoration: none;
}

/* #auth_section .form-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 30px;
} */

.otp-input-group {
    display: flex;
    gap: 10px;
    justify-content: start;
}
#auth_section .otp-label {
    font-size: 17px;
    font-weight: 400;
    color: var(--dark-gray);
    line-height: 19.2px;
}
#auth_section .otp-input {
    width: 30px;
    height: 30px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: var(--dark-gray);
    border-inline: unset;
    border-top: unset;
    border-bottom: 2px solid #2C2D3A;
    background: transparent;
}

#auth_section .resend-link {
    color: #3583FF;
    text-decoration: none;
    cursor: pointer;
}
.login_tab{
    height: 100vh;
}


.custom-date-wrapper {
    position: relative;
    width: 100%;
}

.custom-date-wrapper input {
    /* width: 100%; */
    /* padding: 10px; */
    /* font-size: 16px; */
    background: transparent;
    position: relative;
    /* z-index: 2; */
    /* border: 1px solid #ccc; */
}

.custom-date-wrapper .custom-placeholder {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    color: #A9A9A9;
    font-size: 16px;
    pointer-events: none;
    transition: 0.2s ease all;
    z-index: 999;
    opacity: 1;
    background: #ffff;
}

.custom-date-wrapper input:focus + .custom-placeholder,
.custom-date-wrapper input.has-value + .custom-placeholder {
    opacity: 0;
    visibility: hidden;
}



@media (min-width:576px) {
    #auth_section .form-label {
        margin-bottom: 15px;
    }

    #auth_section .form-label,
    #auth_section .form-check-label {
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        color: var(--dark-gray);
    }
    #auth_section .form_button {
        font-size: 18px;
        height: 60px;
    }
    .right-side-container {
        padding: 35px 10px;
        margin: 0px 60px;
        
    }
    #auth_section .form-control,
#auth_section .form-select {
    padding: 21px 19px !important;
    /* margin-bottom: 25px; */
}
#auth_section .form-group{
    margin-bottom: 25px;
    }
.auth_left_content h3, .geeting_text {
    font-size: 23px;
    line-height: 43.2px;
}
#auth_section .otp-input {
    width: 40px;
    height: 50px;
}

}
@media (min-width:768px) {
    #auth_section .form-label {
        margin-bottom: 15px;
    }

    #auth_section .form-label,
    #auth_section .form-check-label {
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        color: var(--dark-gray);
    }
    #auth_section .forget_password {
        font-size: 18px;
    }
    #auth_section .form_button {
        font-size: 18px;
        height: 60px;
    }
    .right-side-container {
        padding: 35px 10px;
        margin: 0px 60px;
    }
    .right-side-container .tab-content{
        background: unset;
        padding: unset;
        border-radius: unset;
    }
    #auth_section .form-check-input[type="checkbox"] {
        width: 24px;
        height: 24px;
    }
    #auth_section .form-label.confirm_box {
        font-size: 17px;
        font-weight: 500;
    }
    .login_tab{
        height: 100vh;
    }
}

@media (min-width:992px) {

    #auth_section .form-control,
    #auth_section .form-select {
        padding: 20px 19px !important;
        border-radius: 10px;
        border: 1px solid #D0D0D0;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        /* margin-bottom: 25px; */
    }

    #auth_section .otp-input {
        font-size: 16px;
    }
    #auth_section .form-group{
        margin-bottom: 25px;
     }

    #auth_section .form-label,
    #auth_section .form-check-label {
        font-size: 19px;
    }
    #auth_section .form-title {
        font-family: Lato;
        font-size: 30px;
        font-weight: 700;
        line-height: 40.2px;
        color: var(--dark-gray);
        text-align: center;
        margin: 40px 0px;
    }
    .auth_left_content h3 {
        font-size: 36px;
    }
    .auth_left_content {
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: unset;
    }
    .auth_left_content .login-image {
        max-width: 595px;
        height: 390px;
        margin: 0 auto;
        
    }
    .right-side-container {
        padding: 35px 40px;
        margin: 0px 60px;
    }
}

@media (min-width:1200px) {
    .auth_left_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
}
@media (min-width:1300px) {
    .auth_left_content .login-image {
        max-width: 595px;
        height: 390px;
        margin: 0 auto;
       
    }

    .right-side-container {
        padding: 35px 40px;
        margin: 0px 60px;
    }

    #auth_section .form_button {
        font-size: 24px;
        border-radius: 35.5px;
        height: 71px;
    }

    #auth_section .form-control,
    #auth_section .form-select {
        padding: 27px 19px !important;
        border-radius: 10px;
        border: 1px solid #D0D0D0;
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        /* margin-bottom: 27px; */
    }

    #auth_section .otp-input {
        font-size: 18px;
    }
#auth_section .form-group{
margin-bottom: 27px;
}
    #auth_section .form-title {
        font-size: 36px;
        line-height: 43.2px;
        margin: 50px 0px;
    }

}

@media (min-width:1400px) {
    .auth_left_content .login-image {
        max-width: 650px;
        height: 390px;
        margin: 0 auto;
    }

    .right-side-container {
        padding: 35px 40px;
        margin: 0px 90px;
    }

}