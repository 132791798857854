#explore_fund_section {
    padding: 30px 20px;
    background-color: var(--light-gray);
}
/* .explore_fund_content{
    height: 100vh;
} */
.explore_fund_section .left-section-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 0.9px;
    /* margin-bottom: 30px; */
}
 /* .filter_right_card{
height: 100vh;
} */

.filter_left_card{
    /* height: 1000px;
    overflow: hidden;
    overflow-y: auto; */
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.filter_right_card {
    padding: 30px 30px;
    /* height: 1000px;
    overflow-y: auto; */
}
.filter_left_card{
    background-color: #f2f2f2;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 0px;
}
.filter_left_card .card-header{
    background-color: #f2f2f2;
}


.filter_right_card.card ,.filter_right_card .card-header{
    background: #ffffff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
}

.filter_left_card .card-header {
    padding: 20px;
    border-bottom: 2px solid #EDEDED;
}

.filter-heading {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: var(--dark-gray);
    letter-spacing: 0.5px;
}

.filter-heading span {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: right;
    color: var(--primary-third);
    text-transform: uppercase;
}

.filter_right_card .filter_result_heading {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: left;
    color: #555555;
    letter-spacing: 0.4px;
    margin-bottom: 18px;
}

.filter_right_card .filter_result_heading span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    color: #3C3D4F;

}
.filter_right_card {
    padding: 30px 30px;
}
.filter_right_card .search-result-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: left;
    color: #3C3D4F;
    border: 1px solid #C0C3C9;
    padding: 4px 12px;
    border-radius: 13px;
}
.search-result-text img {
    width: 12px;
    height: 12px;
    vertical-align: baseline;
}
/* Custom styles for the checkboxes */
.filter_left_card .form-check-input {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid var(--dark-gray);
}

.filter_left_card .form-check-input:checked {
    background-color: var(--light-blue-color);
    border-color: var(--light-blue-color);
}

.filter_left_card .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");

}

.filter_left_card .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f8f9fa;
    box-shadow: none;
}

.filter_left_card .accordion-item {
    border: none;
    border-bottom: 2px solid #EDEDED;
    padding: 0px 5px;
}
.filter_left_card .accordion-item:last-child {
    border: none;
}
.filter_left_card .accordion-header {
    font-weight: bold;
}

.filter_left_card .accordion {
    --bs-accordion-bg: transparent;

}

.custom_accordion .accordion-button {
    background-color: transparent !important;
}

.custom_accordion_body .form-check-label {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
}

.accordion-button {
    color: var(--dark-gray);
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    letter-spacing: 0.5px;
}

.filter_left_card .accordion-button:not(.collapsed) {
    color: var(--dark-gray);
    background-color: #f8f9fa;
    box-shadow: none;
}
.custom_accordion_body {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.custom_accordion_body .form-check {
    display: flex;
    align-items: center;
}


.search-box {
    padding: 7px 13px;
    background-color: white;
    border: 1px solid #C0C3C9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .search-box:hover {
    border-color: #AAAAAA;
  }
  
  /* .search-box:focus-within {
    border-color: #FF0080;
    box-shadow: 0 0 0 5px rgba(255, 0, 128, 0.40);
  } */
 
  .search-box button img {
    width: 18px;
    height: 18px;
    vertical-align: sub;
}
  .search-box input {
    letter-spacing: 0.5px;
    border: none;
    color: var(--dark-gray);
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: left;
    width: 100%;
}
.search-box input::placeholder{
    color: #9E9E9E;
}
  
  button:hover {
    cursor: pointer;
  }
  
  input:focus {
    outline: none;
  }
  
  input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;	
  }

  .search-box-top {
    padding: 0px 0px 7px 0px;
    background-color: transparent;
    border-bottom: 2px solid #A0A0A0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
}

.search-box-top input {
    letter-spacing: 0.5px;
    border: none;
    color: var(--dark-gray);
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: left;
    width: 100%;
    background-color: transparent;
}
.search-box-top input::placeholder {
    color: #A0A0A0;
}
.search-box-top button img {
    width: 18px;
    height: 18px;
    vertical-align: sub;
}


@media (min-width:768px) {
    .search-box {
        padding: 9px 13px;
    }
    .search-box input {
        font-size: 15px;
    }
    .search-box button img{
        width: 20px;
        height: 20px;
      }
}
@media (min-width:576px) {
    #explore_fund_section {
        padding: 40px 20px;
    }
    .accordion-button {
        font-size: 16px;
    }
    .custom_accordion_body .form-check-label {
        font-size: 16px;
    }
    .custom_accordion_body {
        gap: 10px;
    }
    .filter-heading {
        font-size: 16px;
    }
    .filter_left_card .card-header {
        padding: 24px;
        border-bottom: 2px solid #EDEDED;
    }
    .filter-heading span {
        font-size: 14px;
    }
    

}
@media (min-width:768px) {
    #explore_fund_section {
        padding: 60px 40px;
    }
.custom_accordion_body {
        gap: 12px;
    }
    .filter_right_card {
        padding: 30px 30px;
        height: 1000px;
        overflow-y: auto;
        scrollbar-width: none;
    }
    .filter_left_card{
        height: 1000px;
        overflow: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        scrollbar-width: none;
    }
    .filter_right_card.card ,.filter_right_card .card-header{
        background: #ffffff;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    .filter_left_card{
        background-color: #f2f2f2;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .explore_fund_section .left-section-title {
        font-size: 24px;
    }
    .filter-heading {
        font-size: 18px;
    }

}
@media (min-width:992px) {
    #explore_fund_section {
        padding: 80px 40px;
    }

    .filter_right_card .filter_result_heading {
        font-family: Lato;
        font-size: 16px;
        line-height: 17.07px;
        margin-bottom: 18px;
    }
    .accordion-button {
        font-size: 18px;
    }
    .custom_accordion_body .form-check-label {
        font-size: 17px;
    }
    .custom_accordion_body {
        gap: 15px;
    }
    
}

@media (min-width:1200px) {

    #explore_fund_section {
        padding: 100px 40px;
    }

}

@media (min-width:1400px) {

    #explore_fund_section {
        padding: 100px 40px;
    }

}