/* fund section  */
#fund_section {
  padding: 30px 30px;
  background: var(--light-gray);
}

.fund_section .section-title {
  color: #ffffff;
  text-align: left;
}

.fund_section .fund_invest_text {
  text-align: left;
  color: var(--light-gray);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.start_fund_box .fund-name {
  font-family: "Lato-l";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #ffffff;
  letter-spacing: 1px;
}

.start_fund_box .right-item img {
  width: 30px;
  height: 30px;
}

.start_fund_box .custom-badge {
  font-family: Lato;
  font-weight: 400;
}

.start_fund_box {
  background: linear-gradient(90deg, #5a4ad5 0%, #3583ff 100%);
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  padding: 25px 25px;
}

.fund_section .card {
  background-color: var(--light-white);
}

.fund_section > .card {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  background-color: var(--light-white);
}

.start_fund_box .heading {
  font-family: Lato;
  font-size: 22px;
  font-weight: 600;
  line-height: 34.4px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.start_fund_box .description {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: var(--light-gray);
  letter-spacing: 0.5px;
}

/* calculator  */
.calculator-container {
  background-color: #ffff;
  padding: 24px;
  border-radius: 12px;
  /* max-width: 400px; */
  /* margin: 20px auto; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.calculator-container h5,
.left-section-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #3e4154;
}

.nav-graph-title {
  font-size: 18px;
  font-weight: 500;
  color: #00ac78;
  margin-right: 15px;
}

.nav-section-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #3e4154;
  margin-bottom: 0;
}

.nav-graph-yeartitle {
  font-size: 13px;
  font-weight: 500;
  color: #a3a3a3;
  margin-right: 15px;
}

.nav-graph-percentttitle {
  font-size: 13px;
  font-weight: 500;
  color: #ff7b7b;
  margin-right: 15px;
}

.nav-line-ttitle {
  margin-right: 15px;
}

.fund_section .nav-tabs {
  border-bottom: unset !important;
}

.fund_section .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--light-second-gray);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.fund_section .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: none;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  padding: 12px 14px;
}

.fund_section .nav-link:hover {
  color: var(--primary-second) !important;
}

.fund_section .nav-tabs .nav-link.active {
  color: var(--primary-second) !important;
  /* font-weight: bold; */
  border-color: transparent;
  border-bottom: 3px solid var(--primary-second);
}

.slider-label,
.result-text {
  color: var(--dark-gray);
  margin-bottom: 8px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.slider-value {
  background-color: #e8eafd;
  color: var(--primary-second);
  padding: 5px 12px;
  border-radius: 6px;
  min-width: 60px;
  width: 100px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: right;
}

.slider {
  width: 100%;
  height: 4px;
  background-color: #3c3c432e;
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -6px;
  box-shadow: 0px 6px 13px 0px #0000001f;
  box-shadow: 0px 0.5px 4px 0px #0000001f;
}

.result-text-label {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: var(--dark-gray);
}

.profit-text {
  color: #00ac78;
}
.graph_month_list {
  padding: 2px 11px;
  border-radius: 16px;
  border: 1px solid #ededed;
  cursor: pointer;
  height: 32px;
  font-size: 7px;
  font-weight: 500;
  line-height: 16.4px;
  display: flex;
  align-items: center;
}
.graph_month_wrapper {
  gap: 6px;
}

/* calculator  */
@media (min-width: 425px) {
}

@media (min-width: 576px) {
  #fund_section {
    padding: 80px 40px;
  }

  .slider-value {
    background-color: #e8eafd;
    color: var(--primary-second);
    padding: 5px 12px;
    border-radius: 6px;
    font-weight: bold;
    min-width: 60px;
    text-align: right;
    width: 140px;
    font-size: 16px;
  }

  .start_fund_box {
    padding: 28px 28px;
  }

  .fund_section .nav-tabs .nav-link {
    padding: 16px 24px;
  }
  .graph_month_list {
    padding: 8px 12px;
    border-radius: 16px;
    border: 1px solid #ededed;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.4px;
    display: flex;
    align-items: center;
  }
  .graph_month_wrapper {
    gap: 8px;
  }
}

@media (min-width: 768px) {
  .start_fund_box .fund-name {
    width: 100%;
    text-overflow: unset;
  }

  .custome-card-body {
    padding: 2rem 2rem 0rem 2rem !important;
  }

  .slider::-webkit-slider-thumb {
    height: 28px;
    width: 28px;
  }

  .result-text-label {
    font-size: 18px;
  }

  .start_fund_box {
    padding: 32px 32px;
  }
}

@media (min-width: 992px) {
  #fund_section {
    padding: 65px 40px;
  }

  .start_fund_box .right-item img {
    width: 30px;
    height: 35px;
  }
}

@media (min-width: 1200px) {
  #fund_section {
    padding: 80px 40px;
  }
}

@media (max-width: 992px) {
  /* Tablet and smaller screens */
  .custom-margin-left {
    margin-left: 42px;
  }
}

/* end third sectiion */
