#manage_nominee .form-check-input[type="checkbox"] {
  color: var(--light-blue-color);
  width: 16px;
  height: 16px;
  border: 2px solid #555555;
  margin-right: 10px;
  border-radius: 2px;
  vertical-align: middle;
}

#manage_nominee .form-check-input[type="checkbox"]:checked {
  background-color: #3583ff;
  border-color: #3583ff;
}
#manage_nominee .nominee_title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: var(--dark-gray);
}

#manage_nominee .more_button {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  border: unset;
  background-color: transparent;
  color: #5a4ad5;
  padding: 10px 0px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

#manage_nominee .more_button span {
  background-color: #5a4ad5;
  width: 20px;
  height: 20px;
  display: flex;
  color: #ffff;
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
}

#manage_nominee .delete_button {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  border: unset;
  background-color: transparent;
  color: #a61e09;
  padding: 10px 0px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 30px;
}

#manage_nominee .delete_button span {
  background-color: #a61e09;
  width: 20px;
  height: 20px;
  display: flex;
  color: #ffff;
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
}

.notice {
  color: #555555;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

#manage_nominee .passwordicon {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  width: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

#manage_nominee .passwordicon .color {
  vertical-align: -webkit-baseline-middle;
  background-color: #fff;
  padding: 5px 5px;
  color: #d0d0d0;
  width: 20px;
  height: 18px;
}
