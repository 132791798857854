
.raise-all-same{
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    height: 94px;
    width: 379px;
    border: none;
}
.raise-ticket-gradient {
    background: linear-gradient(195deg, #3583FF, #204F99);
}

.email-ticket-gradient {
    background: linear-gradient(195deg, #34D3C0, #1A5D76);
    background-color: #17a2b8; 
    border-radius: 8px; 
    text-decoration: none;
}

.contact-ticket-gradient {
    background: linear-gradient(187deg, #AF89FF, #1A3476);
    background-color: #6f42c1; 
    border-radius: 8px; 
    text-decoration: none;
}

.looking_help {
    color: #2C2D3A;
    font-size: 18px;
    line-height: 28.8px;
    font-family: Lato-bold;
    margin-bottom: 15px;
}
.help-btn{
gap: 16px;
}

.form_card_customer {
    padding: 30px 30px;
}
.raise-all-same {
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    height: 81px;
    width: 343px;
    border: none;
}
.img_customer_support {
    height: 150px;
    width: 100%;
}
@media (min-width: 576px) {
    .form_card_customer {
        padding: 45px 50px;
    }
    .img_customer_support {
        height: 200px;
        width: 200px;
    }
}
@media (min-width: 768px) {

.raise-all-same{ 
    height: 94px;
    width: 379px; 
}
.looking_help {
    color: #2C2D3A;
    font-size: 22px;
    line-height: 28.8px;
    font-family: Lato-bold;
    margin-bottom: 20px;
}
.form_card_customer {
    padding: 45px 91px;
}
.img_customer_support {
    height: 250px;
    width: 250px;
}
}
@media (min-width: 992px) {

    .form_card_customer {
        padding: 45px 43px;
    }
    .img_customer_support {
        height: 320px;
        width: 313px;
    }
}

@media (min-width: 1200px) {

.raise-all-same{ 
    height: 94px;
    width: 379px; 
}
.looking_help {
    color: #2C2D3A;
    font-size: 24px;
    line-height: 28.8px;
    font-family: Lato-bold;
    margin-bottom: 30px;
}
.form_card_customer { 
    padding: 45px 111px;
}
.img_customer_support {
    height: 320px;
    width: 313px;
}
}

@media (max-width: 1200px) {

.raise-all-same{
    width: 100%
}
.help-btn{
    margin-top:16px ;
}
}
@media (max-width: 768px) {

    .raise-all-same {
        height: 60px;
        font-size: 15px;
    }
    
  
}

@media (max-width: 576px) {
    .raise-all-same {
        height: 51px;
        font-size: 12px; 
        /* padding: 0px !important; */
    }

    .contact_img,.email_image,.raise_ticket_img {
        height: 21px;
    }
 
}