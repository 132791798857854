/* fund section  */
  
.start_fund_box .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}
  
.start_fund_box .fund-name-header {
    font-family: 'Lato-l';
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 1px;
    cursor: pointer;
}

.start_fund_box .right-item .bookmark {
    width: 25px;
    height: 30px;
}
.start_fund_box .right-item img {
    width: 30px;
    height: 30px;
}

.start_fund_box .custom-badge {
    font-family: Lato;
    font-weight: 400;

}

.start_fund_box {
    background: linear-gradient(90deg, #5A4AD5 0%, #3583FF 100%);
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    padding: 25px 25px;

}
.start_fund_box .heading {
    font-family: Lato;
    font-size: 22px;
    font-weight: 600;
    line-height: 34.4px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.start_fund_box .description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--light-gray);
    letter-spacing: 0.5px;
}
.invest_btn {
    text-align: center;
    padding: 12px 28px;
    border-radius: 24px;
    transition: 0.5s all ease;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
}

.invest_btn_white{
    color: var(--dark-gray);
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.invest_btn_white:hover,.invest_btn_white:active{
    background-color: transparent;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.invest_btn_blue{
    color: #FFFFFF;
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 1px solid linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
}
.invest_btn_blue:hover,.invest_btn_blue:active{
    color: #FFFFFF;
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
    border: 1px solid linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
}
.right-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}


@media (min-width:576px) {
    .start_fund_box {
        padding: 28px 28px;
    
    }
}

@media (min-width:700px) {
    .right-item {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .invest_btn {
        text-align: center;
        padding: 15px 35px;
        border-radius: 24px;
        transition: 0.5s all ease;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 15.6px;
    }
}
@media (min-width:768px) {

    .start_fund_box .fund-name-header {
        width: 100%;
        text-overflow: unset;
    }
    .start_fund_box {
        padding: 32px 32px;
    
    }

}
@media (min-width:992px) {
    .start_fund_box .right-item .bookmark {
        width: 30px;
        height: 35px;
    }
    .start_fund_box .right-item img {
        width: 36px;
        height: 36px;
    }
    .start_fund_box .fund-name-header {
        width: 100%;
        text-overflow: unset;
        font-size: 18px;
    }
}
@media (min-width:1200px) {
         
    .start_fund_box .fund-logo {
    width: 58px;
    height: 58px;
}
  
}
