
.cards_box {
    perspective: 1000px;
    width: 100%;
    min-height: 400px;
}

.flip-box {
    width: 100%;
    height: 100%;
    position: relative;
}

.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border: 0px;
}


.cards_box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
    padding: 20px;
    border: 0px;
}

.flip-box-front {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-box-back {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
}


.flip-box-front .card-title {
    font-family: Lato;
    font-size: 26px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.5px;
    
}
.flip-box-back .card-title {
    font-family: Lato;
    font-size: 26px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.5px;
}


.flip-box-back .card-title span {
font-family: Lato-l;
font-size: 22px;
font-weight: 600;
line-height: 25.8px;
text-align: center;


}

.flip-box-front .card-title span {
    font-size: 22px;
    font-weight: 400;
}

.flip-box-back .card-details {
    margin-bottom: 15px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: #ffff;
}



.card-sip {
    background: linear-gradient(216.6deg, #3583FF 0.04%, #204F99 81.67%);
}

.card-swp {
    background: linear-gradient(216.6deg, #34D3C0 16.13%, #1A5D76 100%);
}

.card-wealth {
    background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
}


.cards_main_box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 0px;
}
.cards_section>div {
    padding: 35px 30px;
}

@media (min-width: 576px) {
    .cards_section>div {
        padding: 40px 40px;
    }
    .cards_main_box {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    .flip-box-front .card-title {
        font-size: 28px;
    }

    .flip-box-front .card-title span {
        font-size: 24px;
    }
}

@media (min-width: 768px) {
    .cards_section>div {
        padding: 60px 60px;
    }
    .cards_main_box {
        grid-template-columns: 1fr 1fr;
        gap: 25px;
        padding: 20px;
    }

   .flip-box-front .card-title {
    font-size: 40px;
    line-height: 42.6px;
    }
   .flip-box-front .card-title {
    font-size: 32px;
    line-height: 30.2px;
    }

    .flip-box-front .card-title span {
        font-size: 23px;
    }

    .flip-box-back .card-details {
        font-size: 16px;
    }
   
}

@media (min-width: 992px) {
    .cards_section>div {
        padding: 0px 100px;
    }
    .cards_main_box {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
    }

    .flip-box-front .card-title {
        font-size: 48px;
    }
    .flip-box-back .card-title {
        font-size: 33px;
        line-height: 43.2px;
    }

   .flip-box-front .card-title span {
        font-size: 28px;
    }

    .flip-box-back .card-details {
        font-size: 16px;
    }


}

@media (min-width: 1200px) {

    .cards_section>div {
        padding: 78px;
    }
    .flip-box-back .card-title {
      font-size: 33px;
      line-height: 43.2px;
  }

  
}

@media (min-width: 1300px) {

  .cards_section>div {
      padding: 78px;
  }
  .flip-box-back .card-title {
    font-size: 36px;
    line-height: 43.2px;
}


}

/* plans section  */

#plan_section {
  background-color: var(--light-gray);
  padding: 35px 15px;
}

.plan_section .section-title {
  color: var(--dark-gray);
}

.plan_section .section-title span {
  color: var(--light-blue-color);
}

.goal-card {
  border: 2px solid #AA89FF;
  padding: 33px 35px;
  border-radius: 12px;
  text-align: center;
  transition: 0.2s ease;
  color: var(--dark-gray);
  background-color: transparent;
  display: flex;
  gap: 21px;
  align-items: center;
  overflow: hidden;
}

.goal-card {
  border: 2px solid #AA89FF;
  padding: 30px 30px;
  border-radius: 12px;
  text-align: center;
  transition: 0.2s ease;
  color: var(--dark-gray);
  background-color: transparent;
  display: flex;
  gap: 21px;
  align-items: center;
  overflow: hidden;
}

.goal-card h5 {
  margin-top: 10px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.goal-card.active,
.goal-card:hover {
  background: linear-gradient(135deg, #76a1fe, #a27bff);
  color: #fff;

}

.goal-card.active img,
.goal-card:hover img {
  filter: invert(1) brightness(3.5);
}

.goal_planner_section {
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #E1DEDC;
  border: none;
}

.goal_planner_section h5,
.left-section-title {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: var(--dark-gray);

}

.card_divider {
  border: 1px solid #E4DAD1
}

.chart_section .Investment_color_box {
  width: 20px;
  height: 20px;
  display: block;
  background-color: var(--light-blue-color);
  border-radius: 4px;
}

.chart_section .color_box {
  background-color: var(--pista-green) !important;
}

.no-scrollbar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.no-scrollbar::-webkit-scrollbar {
  display: none; 
}

.chart_header h6 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: var(--dark-gray);
}

.investment_cards .card {
  background-color: var(--light-white);
  border-radius: 12px;
  min-height: 300px;
}

.investment_cards h6 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: var(--dark-gray);
}

.investment_cards span {
  color: var(--primary-second);
  font-weight: 700;
}

.investment_cards h5 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #555555;
}

.investment_cards h5 span {
  color: var(--dark-gray);
  font-weight: 700;
}
.chart_div {
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
}
/* end plan section  */
/* invest section  */
#fund_invest_section {
  padding: 45px 40px;
}

.fund_invest_section .section-title {
  color: #FFFFFF;
  text-align: center;
}
.error-message{
  color: red;
  margin-top: 5px;
  font-size: 15px;
}

.fund_invest_section .fund_invest_text {
  text-align: left;
  color: var(--light-gray);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

.fund_invest_box {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 35px;

}


.fund_invest_box .fund_invest_box_child {
  background: linear-gradient(180deg, #30267C 0%, #42369B 100%);
  padding: 40px 40px;
  border-radius: 12px;
  border: 2px solid;
  /* border-image-source: linear-gradient(180deg, #4235A7 0%, #322978 100%); */
  border-image-slice: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fund_invest_box_child:hover {
  background: linear-gradient(216.6deg, #34D3C0 16.13%, #1A5D76 100%);
  border: 2px solid linear-gradient(216.6deg, #34D3C0 16.13%, #1A5D76 100%);
}

.fund_invest_box .fund_invest_box_child span {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  color: var(--light-gray);

}
.fund_invest_box_child:hover img {
  filter: invert(1) brightness(10.5);
}

/* end invest section  */


@media (min-width:576px) {
  #plan_section {
      padding: 80px 40px;
  }

  #fund_invest_section {
      padding: 80px 40px;
  }

  .fund_invest_box {
      display: grid;
      grid-template-columns: 200px 200px;
      grid-column-gap: 35px;
      grid-row-gap: 35px;

  }

}

@media (min-width:768px) {
  .fund_invest_box {
      display: grid;
      grid-template-columns: 240px 240px;
      grid-column-gap: 40px;
      grid-row-gap: 40px;

  }

}

@media (min-width:992px) {
  .fund_invest_section .section-title {
    color: #FFFFFF;
    text-align: left;
  }
  .fund_invest_section .fund_invest_text {
    text-align: left;
  }

}
@media (min-width:1200px) {
  #plan_section {
      padding: 100px 40px;
  }

  #fund_invest_section {
      padding: 100px 40px;
  }

}

@media (min-width:1400px) {
  .goal-card {
      padding: 33px 35px;
  }

  .goal-card h5 {
      font-size: 18px;
  }
}


/* download section  */
#download_section {
  background-color: var(--light-gray);
}

.download_inner_content.align-items-center {
  display: grid;
  grid-template-columns: 100%;
}

.phone-image-container {
  height: 550px;
}

.phone-image-container:before {
  content: "";
  position: absolute;
  width: 100%;
  background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
  height: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  display: table-cell;
}


.download_inner_content .section-title {
  font-family: Lato-bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 22.2px;
  text-align: center;
}

.download_inner_content div:first-child {
  width: 100%;
  max-width: 900px;
  padding: 40px;
}


.download_inner_content .section-description {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  color: #555555;
  width: 100%;
}

.download_inner_content .brand-name {
  font-family: Archivo_Black;
  font-size: 40px;
  font-weight: 400;
  line-height: 43.52px;
  letter-spacing: 0.53em;
  text-align: center;
  color: #FFFFFF;
  opacity: 50%;
  transform: rotate(-90deg);
  position: absolute;
  top: 43%;
  z-index: 3;
  right: -120px;
  /* bottom: 0; */
}

.phone-image {
  position: absolute;
  z-index: 3;
  right: 50%;
  top: 36px;
  /* left: 0; */
}

.download-buttons .btn img {
  width: 130px;
}

@media (min-width:576px) {
  .download_inner_content .section-title {
    font-size: 26px;
  }
  .download-buttons .btn img{
    width: 150px;
    }
  
}
@media (min-width:768px) {
  .download_inner_content .section-title {
    font-family: Lato-bold;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: left;
  
  }
  .download_inner_content .section-description {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #555555;
    width: 100%;
  }


}

@media (min-width:992px) {
  .download-buttons .btn img{
    width: 185px;
    }
}
@media (min-width:1024px) {
  .download_inner_content.align-items-center {
      display: grid;
      grid-template-columns: 70% 30%;
  }

  .phone-image-container {
      height: 650px;
  }

  .download_inner_content div:first-child {
      width: 75%;
      max-width: 100%;
      padding: 0px;
  }

  .phone-image-container:before {
      content: "";
      position: absolute;
      width: 100%;
      background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
      height: 100%;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      display: table-cell;
  }

  .download_inner_content .section-description {
      font-size: 16px;
      line-height: 19.2px;
      width: 500px;
  }

  .phone-image {
      position: absolute;
      z-index: 3;
      right: 60%;
      top: 93px;
      display: table-cell;
      vertical-align: middle;
  }

  .download_inner_content .brand-name {
      font-size: 40px;
      line-height: 43.52px;
      top: 43%;
      right: -120px;
      /* bottom: 0; */
  }

  .phone-image {
      position: absolute;
      z-index: 3;
      right: 256px;
      top: 63px;
  }

}

@media (min-width:1200px) {
  .download_inner_content.align-items-center {
      display: grid;
      grid-template-columns: 70% 30%;
  }

  .phone-image-container {
      height: 650px;
  }

  .download_inner_content div:first-child {
      width: 75%;
      max-width: 100%;
  }

  .phone-image-container:before {
      content: "";
      position: absolute;
      width: 100%;
      background: linear-gradient(216.47deg, #AF89FF 15.53%, #1A3476 112.82%);
      height: 100%;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      display: table-cell;
  }

  .download_inner_content .section-description {
      font-size: 16px;
      line-height: 19.2px;
      width: 500px;
  }

  .phone-image {
      right: 73%;
      top: 93px;
  }

  .download_inner_content .brand-name {
      font-size: 40px;
      font-weight: 400;
      line-height: 43.52px;
      letter-spacing: 0.53em;
      text-align: center;
      color: #FFFFFF;
      opacity: 50%;
      transform: rotate(-90deg);
      position: absolute;
      top: 43%;
      z-index: 3;
      right: -112px;
      /* bottom: 0; */
  }
}

/* end download section  */


