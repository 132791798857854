/* funds card  */
.Sip-card {
    border-radius: 12px;
    box-shadow: unset;
    border: 0px;
    padding: 25px;
}
.Sip-card .fund-logo {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: #f0f0f0;
}

.Sip-card .fund-name {
    color: var(--dark-gray);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-wrap: wrap;
}

.Sip-card:hover .fund-name,
.Sip-card:hover .fund-name {
    color: var(--primary-second);
}

.Sip-card .fund-details-right .bookmark-icon {
    filter: none;
}

.bookmarked .bookmark-icon {
    filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(3000%) hue-rotate(210deg) brightness(95%) contrast(100%);
}

.custom-badge {
    border-radius: 20px;
    padding: 5px 13px;
    font-family: Lato;
    font-size: 11px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    letter-spacing: 1px;
}

.green-badge {
    background-color: var(--light-green);
    color: var(--dark-green);
}

.purple-badge {
    background-color: var(--light-purple);
    color: var(--primary-second);
}

.Sip-card .form-lable {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
    text-wrap: nowrap;
    margin-bottom: 8px;
}

.Sip-card .form-select {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
    background: #FFFFFF;
}

.Sip-card .form-control {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: var(--dark-gray);
    background: #FFFFFF;
}
.Sip-card .form-select:disabled {
    background: #D1D1D1;
    border: 1px solid #C0C3C9;
}
.Sip-card .form-control:disabled {
    background: #D1D1D1;
    border: 1px solid #C0C3C9;
}
.right-toggle-section {
    background-color: #EAE7FF;
    border-radius: 8px;
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
}



@media (min-width:1024px) {}

@media (min-width:1300px) {}

@media (min-width:1400px) {}


.investment-inputs .custom-select2-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
/* General Styling */
.investment-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    /* Adjust spacing between inputs */
}

.input_group {
    flex: 1 1 9%;
}


.form-control:focus,
.form-select:focus {
    border-color: #3583ff;
    /* Primary blue color */
    box-shadow: 0 0 4px rgba(53, 131, 255, 0.3);
}

/* Delete Button */
.delete-action {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.delete-action .btn {
    background-color: #ff4d4d;
    border: none;
    color: #ffffff;
    font-size: 16px;
    padding: 0.5rem 0.75rem;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
}

.delete-action .btn:hover {
    background-color: #e04343;
    box-shadow: 0 2px 6px rgba(255, 77, 77, 0.4);
}

/* Toggle Switches */
.toggle-group {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.toggle-option {
    font-size: 14px;
    font-weight: 600;
    color: #2C2D3A;
    font-family: 'Lato-l';
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.toggle-option input[type="radio"] {
    margin-right: 0.4rem;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.step-up-toggle {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    gap: 0.5rem;
}

@media (min-width:576px) {
    .divider_toggle {
        display: inline-block;
        width: 24px;
        vertical-align: super;
        transform: rotate(90deg);
        border: 1px solid #C0C3C9;
        margin: 0px 10px;
    }

    .right-toggle-section {
        background-color: #EAE7FF;
        border-radius: 8px;
        padding: 10px 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
   
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
    .input_group {
        flex: 1 1 23%;
    }

    .delete-action {
        justify-content: flex-start;
    }
}
@media (max-width: 576px) {
    .input_group {
        flex: 1 1 100%;
    }

    .delete-action {
        justify-content: flex-start;
    }
}